import React from 'react'
import { Anchor } from 'antd'
import './styles/intro.css'

const { Link } = Anchor

const LandingPage = (props) => {
  return (
    <>
      <div
        style={{
          minHeight: props.anchor === 'root' ? '89vh' : '95vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
        id={props.anchor}
      >
        {props.children}
      </div>
      <Anchor
        style={{
          fontSize: 'clamp(100%, 1rem + 2vw, 40px)',
          fontFamily: 'Dancing Script',
          width: 150,
        }}
      >
        <Link href={`#${props.next_anchor}`} title={props.next_title} />
      </Anchor>
    </>
  )
}

export default LandingPage
