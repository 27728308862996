import React from 'react'
import { LogoutOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { ButtonContainer } from '../../widgets/Navigation'
import { setUserProfile, signoutUser } from '../../external/FirebaseAPI'
import { useDispatch, useSelector } from 'react-redux'
import {
  CLEAR_PROJECTS,
  REPOSITORIES_LOADING,
  SET_REPOSITORIES,
} from '../../model/projects'
import { GITHUB_MEMBERSHIP } from '../../model/github'

const Logout = () => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)

  const logoutUser = () => {
    signoutUser()
    dispatch({ type: CLEAR_PROJECTS })
    dispatch({ type: SET_REPOSITORIES, payload: [] })
    dispatch({ type: REPOSITORIES_LOADING, payload: false })
    dispatch({ type: GITHUB_MEMBERSHIP, payload: {} })
    const additions = {
      findUpdatesFrom: user.profile?.lastLogin
        ? user.profile.lastLogin
        : Date.now(),
    }
    // create a profile in database
    setUserProfile(user.uid, { ...user.profile, ...additions })
  }
  return (
    <Button
      size="middle"
      style={{ width: '100%', height: '100%' }}
      type="link"
      onClick={() => logoutUser()}
    >
      <ButtonContainer>
        <LogoutOutlined />
      </ButtonContainer>
      Logout
    </Button>
  )
}

export default Logout
