import React from 'react'
import IProject from '../../model/types/IProject'
import PledgedProjectProgress from './PledgedProjectProgress'

export interface IProjectProgressProps {
  project: IProject
}

const ProjectProgress = (props: IProjectProgressProps) => {
  const { project } = props
  const pledge_target = project.pledge_target
  const pledged = project.pledged

  const complete = (
    (parseInt(pledged) / parseInt(pledge_target)) *
    100
  ).toFixed(2)

  return pledge_target ? (
    <PledgedProjectProgress
      complete={complete}
      pledge_target={pledge_target}
      pledged={pledged}
    />
  ) : null
}

export default ProjectProgress
