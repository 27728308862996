import React from 'react'
import LandingPage from './LandingPage'
import { Button } from 'antd'
import { useHistory } from 'react-router'
import LandingPageContent from './LandingPageContent'

const Projects = () => {
  const history = useHistory()

  return (
    <LandingPage anchor="projects" next_anchor="creation" next_title="Creation">
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <LandingPageContent
          image="projects.png"
          imageAlt="FMAY: Projects"
          content={[
            <p>Everything thing big or small is a project at FMAY.</p>,
            <p>
              A project drives all stages of development from idea to delivery.
            </p>,
            <div className="fmay-software--landing-page--projects-list">
              <p>Each FMAY project comes complete with:</p>
              <ul>
                <li>a code repository</li>
                <li>an issue tracker</li>
                <li>a listing on FMAY</li>
                <li>development status tracking</li>
                <li>pledge tracking</li>
                <li>and more...</li>
              </ul>
            </div>,
            <p>
              Through this site FMAY software provides clarity on all aspects of
              every project at every stage.
            </p>,
            <Button
              onClick={() => history.push('/projects', { directed: true })}
              size={'small'}
              type="primary"
            >
              Active Projects
            </Button>,
          ]}
        />
      </div>
    </LandingPage>
  )
}

export default Projects
