import { IssuesOpenedEvent, Schema } from '@octokit/webhooks-types'
import { useCallback, useEffect } from 'react'
import { useFmayUpdates } from '../../../hooks/useFmayUpdates'
import { usingEvent } from '../../../model/types/fmayEvents/Webhooks'
import { FMAYUpdateListener } from '../../../model/types/FMAYUpdateListener'

export const useClientListener = (
  issuesOpened: (event: IssuesOpenedEvent) => void
): void => {
  const { registerListener, unregisterListener } = useFmayUpdates()

  const listener: FMAYUpdateListener = {
    id: 'projects',
    onMessage: (data: Schema) => {
      usingEvent(data)
        .onIssuesOpened()
        .do(() => issuesOpened(data as IssuesOpenedEvent))
    },
  }

  const localRegisterListener = useCallback(
    (listener: FMAYUpdateListener) => {
      registerListener(listener)
    },
    [registerListener]
  )

  const localUnregisterListener = useCallback(
    (listener: FMAYUpdateListener) => {
      unregisterListener(listener)
    },
    [unregisterListener]
  )

  useEffect(() => {
    localRegisterListener(listener)
    return () => {
      localUnregisterListener(listener)
    }
  }, [listener, localRegisterListener, localUnregisterListener])
}
