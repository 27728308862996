import React from 'react'
import { Avatar, Button, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faProjectDiagram,
  faUsersCog,
  faFileInvoice,
  faSignInAlt,
  faBiohazard,
} from '@fortawesome/free-solid-svg-icons'
import { useHistory } from 'react-router'
import Logout from '../sections/registered/Logout'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { IUser } from '../model/types/auth/IUser'

export const ButtonContainer = (props: {
  children: JSX.Element | JSX.Element[]
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-around',
        padding: 2,
      }}
    >
      {props.children}
    </div>
  )
}

interface NavigationButtonProperties {
  show?: boolean
  title: string
  link: string
  icon: IconProp
}

const NavigationButton = (props: NavigationButtonProperties) => {
  const history = useHistory()
  if (
    props.show !== undefined &&
    (props.show === null || props.show === false)
  ) {
    return null
  }
  return (
    <Button
      size="middle"
      type="link"
      style={{
        height: '100%',
      }}
      onClick={() => history.push(props.link, { directed: true })}
    >
      <ButtonContainer>
        <FontAwesomeIcon icon={props.icon} />
        <div>{props.title}</div>
      </ButtonContainer>
    </Button>
  )
}

const getProviderName = (providerId: string) => {
  if (providerId === 'google.com') {
    return 'Google Account'
  } else {
    return 'GitHub Account'
  }
}

export interface NavigationProperties {
  user: IUser
  admin: boolean
}

const Navigation = (props: NavigationProperties) => {
  const user = props.user
  const history = useHistory()
  const isDirect =
    window.location.hostname.startsWith('client') ||
    window.location.hostname.startsWith('contributor')
  const isDirectLoggedIn = isDirect && user && user.uid !== undefined

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'center',
        backgroundColor: 'rgba(103, 120, 135, .8)',
        borderRadius: 2,
        borderWidth: 1,
        borderColor: 'dodgerblue',
        borderStyle: 'solid',
      }}
    >
      <NavigationButton
        icon={faProjectDiagram}
        title="Projects"
        link="/projects"
        show={!isDirect}
      />
      <NavigationButton
        icon={faUsersCog}
        title="Contributions"
        link="/contributor"
        show={!isDirect}
      />
      <NavigationButton
        icon={faFileInvoice}
        title="Requests"
        link="/requests"
        show={!isDirect || isDirectLoggedIn}
      />
      <NavigationButton
        icon={faBiohazard}
        title="Administration"
        link="/admin/accounts"
        show={props.admin !== undefined && props.admin === true}
      />
      {user && user.photoURL && !isDirect ? (
        <ButtonContainer>
          <Tooltip
            placement="bottom"
            title={() => (
              <>
                <p style={{ marginBottom: 0 }}>
                  {getProviderName(user.providerId)}
                </p>
                <p style={{ marginBottom: 0 }}>{user.displayName}</p>
                <p style={{ marginBottom: 0 }}>{user.email}</p>
              </>
            )}
          >
            <Button
              onClick={() => history.push('/login')}
              style={{ height: '100%' }}
              type="link"
            >
              <Avatar size={32} src={user.photoURL} />
            </Button>
          </Tooltip>
        </ButtonContainer>
      ) : !user ? (
        <NavigationButton
          icon={faSignInAlt}
          title="Sign In"
          link={isDirect ? '/' : '/login'}
        />
      ) : (
        <Logout />
      )}
    </div>
  )
}

export default Navigation
