import { Card } from 'antd'
import React from 'react'
import { useHistory } from 'react-router'

const ProjectsCard = (props) => {
  const history = useHistory()
  const { title, description, image, target } = props
  return (
    <Card
      hoverable={true}
      onClick={() => history.push(target, { directed: true })}
      style={{
        minHeight: 250,
        maxHeight: 250,
        width: '20rem',
        borderColor: 'dodgerblue',
        margin: 15,
      }}
      cover={
        image ? (
          <div
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              padding: 10,
            }}
          >
            <img
              style={{
                objectFit: 'contain',
                maxWidth: 250,
                height: 100,
              }}
              alt={'Consign Type'}
              src={image}
            />
          </div>
        ) : undefined
      }
    >
      <Card.Meta title={title} description={description} />
    </Card>
  )
}

export default ProjectsCard
