import React from 'react'
import { Radio, Tooltip } from 'antd'
import { AppstoreOutlined, BarsOutlined } from '@ant-design/icons'

const radioOptions = [
  {
    icon: <AppstoreOutlined />,
    value: 'cards',
    tooltip: 'Show as cards',
  },
  {
    icon: <BarsOutlined />,
    value: 'listView',
    tooltip: 'Show as list',
  },
]

const ProjectsViewSwitch = (props) => {
  const { radioValue, setRadioValue } = props
  return (
    <Radio.Group
      buttonStyle="solid"
      onChange={(e) => setRadioValue(e.target.value)}
      value={radioValue}
      size="middle"
    >
      {radioOptions.map(({ icon, tooltip, value }) => (
        <Tooltip placement="top" title={tooltip}>
          <Radio.Button style={{ fontSize: '18px' }} value={value}>
            {icon}
          </Radio.Button>
        </Tooltip>
      ))}
    </Radio.Group>
  )
}

export default ProjectsViewSwitch
