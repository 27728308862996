import React from 'react'
import { Collapse, List } from 'antd'
import IProject from '../../model/types/IProject'
import { IRecentActivity } from '../../model/types/IRecentActivity'
import { useRecentActivity } from './hooks/useRecentActivity'
const { Panel } = Collapse

export interface RecentActivityProperties {
  projects: IProject[]
}

const RecentActivity = (props: RecentActivityProperties) => {
  const { projects } = props
  const { recentActivity } = useRecentActivity(projects)
  return (
    <List
      style={{ paddingBottom: 10 }}
      itemLayout="vertical"
      dataSource={recentActivity}
      size="small"
      pagination={{
        pageSize: 3,
        size: 'small',
        style: {
          textAlign: 'center',
          display: recentActivity.length === 0 ? 'none' : 'block',
        },
        showQuickJumper: true,
      }}
      renderItem={(item: IRecentActivity) => (
        <List.Item key={`${item.title}-${Math.random()}`}>
          <List.Item.Meta
            title={
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '25px 150px',
                  fontSize: 14,
                  textAlign: 'left',
                }}
              >
                <span
                  style={{
                    marginRight: 3,
                    color: 'dodgerblue',
                    cursor: 'pointer',
                  }}
                >
                  {item.number}
                </span>
                <span style={{ color: 'dodgerblue', cursor: 'pointer' }}>
                  {item.title}
                </span>
              </div>
            }
            description={
              item.body ? (
                <Collapse ghost expandIconPosition="right">
                  <Panel
                    key={item.number}
                    header={
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        {new Date(item.created_at).toLocaleString('en-US', {
                          month: '2-digit',
                          day: '2-digit',
                          year: '2-digit',
                          hour: 'numeric',
                          minute: '2-digit',
                          hour12: true,
                        })}
                      </div>
                    }
                  >
                    <div
                      style={{
                        textAlign: 'justify',
                      }}
                    >
                      {item.body?.replace(/<\/?[^>]+(>|$)/g, ' ')}
                    </div>
                  </Panel>
                </Collapse>
              ) : (
                <div
                  style={{
                    textAlign: 'left',
                    color: 'black',
                  }}
                >
                  {new Date(item.created_at).toLocaleString('en-US', {
                    month: '2-digit',
                    day: '2-digit',
                    year: '2-digit',
                    hour: 'numeric',
                    minute: '2-digit',
                    hour12: true,
                  })}
                </div>
              )
            }
          />
        </List.Item>
      )}
    />
  )
}

export default RecentActivity
