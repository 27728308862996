import React from 'react'
import LandingPage from './LandingPage'
import LandingPageContent from './LandingPageContent'

const Creation = () => {
  return (
    <LandingPage anchor="creation" next_anchor="support" next_title="Support">
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <LandingPageContent
          image="idea.png"
          imageAlt="FMAY: Creation by us"
          content={[
            <p>
              Our thoughts and yours implemented. The goal of FMAY is to produce
              complete products, enhancements, or pretty anything relating to
              software.
            </p>,
            <p>
              Some of the projects are ideas that we have at FMAY. Others come
              from anywhere, whether it is you, your neighbor or even your
              children.
            </p>,
            <p>
              All projects that are being worked on at FMAY are open to
              scrutiny. Each project will have its own website and will have an
              issue tracker associated. Feel free to add any suggestions in the
              form of a ticket in a projects issue tracker.
            </p>,
            <p>
              Have some ideas that need implementation? Contact FMAY with your
              thoughts. All ideas will be considered. Any idea that FMAY will
              move forward on will follow the Pledging Model. As with all of
              FMAY’s creations these projects will be open sourced.
            </p>,
          ]}
        />
      </div>
    </LandingPage>
  )
}

export default Creation
