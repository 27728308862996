import React from 'react'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import firebase from 'firebase/app'
import 'firebase/auth'
import SectionContainer from '../main/SectionContainer'
import { useDispatch, useSelector } from 'react-redux'
import { Avatar, Button } from 'antd'
import Profile from './pledgor/Profile'
import {
  GITHUB_ACCESS_TOKEN,
  GITHUB_KEY,
  GITHUB_MEMBERSHIP,
} from '../../model/github'
import { getUserMembership } from '../../external/GitUser'
import { getGHKey } from '../../external/S3API'
import {
  CLEAR_PROJECTS,
  REPOSITORIES_LOADING,
  SET_REPOSITORIES,
} from '../../model/projects'
import { setCredential } from '../../model/credential'

const Login = (props) => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)

  // Configure FirebaseUI.
  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // We will display Google and Github as auth providers.
    signInOptions: [
      {
        provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        customParameters: {
          // Forces account selection even when one account
          // is available.
          prompt: 'select_account',
        },
      },
      {
        provider: firebase.auth.GithubAuthProvider.PROVIDER_ID,
        scopes: ['repo', 'read:org'],
      },
    ],
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: (result) => {
        if (result.credential.providerId === 'github.com') {
          getGHKey().then((key) => {
            dispatch({ type: GITHUB_KEY, payload: key })
            getUserMembership(
              'FMAY-Software',
              result.credential.accessToken
            ).then((membership) => {
              dispatch({
                type: GITHUB_ACCESS_TOKEN,
                payload: result.credential.accessToken,
              })
              dispatch({
                type: GITHUB_MEMBERSHIP,
                payload: membership,
              })
            })
          })
        } else {
          dispatch({ type: GITHUB_MEMBERSHIP, payload: {} })
          dispatch({ type: GITHUB_ACCESS_TOKEN, payload: '' })
        }
        dispatch(setCredential(result.credential))
        return false
      },
    },
  }

  const logout = () => {
    firebase.auth().signOut()
    dispatch({ type: CLEAR_PROJECTS })
    dispatch({ type: SET_REPOSITORIES, payload: [] })
    dispatch({ type: REPOSITORIES_LOADING, payload: false })
    dispatch({ type: GITHUB_MEMBERSHIP, payload: {} })
    dispatch({ type: GITHUB_ACCESS_TOKEN, payload: undefined })
  }

  const loggedIn = user && user.displayName

  return (
    <SectionContainer
      title={user && user.displayName ? 'Change Account' : 'Sign In'}
      alwaysShow={true}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        {loggedIn ? (
          <>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                flexWrap: 'wrap',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  flexDirection: 'column',
                  height: 175,
                }}
              >
                <Avatar style={{ marginRight: 5 }} src={user.photoURL} />
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                  }}
                >
                  <span>Google Account</span>
                  <span>{user.displayName}</span>
                  <p style={{ marginBottom: 0 }}>{user.email}</p>
                </div>
                <Button onClick={() => logout()} size="small">
                  Switch Accounts
                </Button>
              </div>
              <div
                style={{
                  padding: 10,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              >
                <p>FMAY Profile</p>
                <Profile />
              </div>
            </div>
          </>
        ) : (
          <>
            <StyledFirebaseAuth
              uiConfig={uiConfig}
              firebaseAuth={firebase.auth()}
            />
          </>
        )}
      </div>
    </SectionContainer>
  )
}

export default Login
