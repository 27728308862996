import { Button } from 'antd'
import React, { useState } from 'react'
import { EditorState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import CustomEditor from '../../widgets/editor/CustomEditor'

const PostComment = (props) => {
  const { show, setPostingComment, addNewComment } = props
  const [newComment, setNewComment] = useState(EditorState.createEmpty())

  if (!show) {
    return null
  }

  const postComment = () => {
    addNewComment(draftToHtml(convertToRaw(newComment.getCurrentContent())))
    setNewComment('')
  }

  return (
    <>
      <CustomEditor
        editorState={newComment}
        onEditorStateChange={(editorState) => setNewComment(editorState)}
        placeholder="Enter comment here"
      />
      <div style={{ display: 'flex', padding: 10 }}>
        <Button
          style={{ marginRight: 5 }}
          onClick={() => setPostingComment(false)}
        >
          Cancel
        </Button>
        <Button onClick={() => postComment()}>Post</Button>
      </div>
    </>
  )
}

export default PostComment
