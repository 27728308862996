import React from 'react'

/**
 * Credit: https://learnersbucket.com/examples/javascript/how-to-format-phone-number-in-javascript/
 */
const formatPhoneNumber = (str) => {
  //Filter only numbers from the input
  let cleaned = ('' + str).replace(/\D/g, '')

  //Check if the input is of correct
  let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)

  if (match) {
    //Remove the matched extension code
    //Change this to format for any country code.
    let intlCode = match[1] ? '+1 ' : ''
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
  }

  return null
}

const OwnerInfoCard = (props) => {
  const { owner } = props
  const phone = owner.phone ? formatPhoneNumber(owner.phone) : ''

  var ownerInfo = (
    <>
      <p>Contact FMAY</p>
      <p>
        Email: <a href={`mailto:${owner.email}`}>{`${owner.email}`}</a>
      </p>
    </>
  )
  if (phone) {
    ownerInfo = (
      <>
        <p>
          Phone: <a href={`tel:${phone}`}>{`${phone}`}</a>
        </p>
        <p>
          Email: <a href={`mailto:${owner.email}`}>{`${owner.email}`}</a>
        </p>
      </>
    )
  }

  return ownerInfo
}

export default OwnerInfoCard
