import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { setUserProfile } from '../../external/FirebaseAPI'
import { IUser } from '../../model/types/auth/IUser'
import useProjects, { STATES } from '../projects/hooks/useProjects'

export interface AsContributorProps {
  children: JSX.Element
  alternate?: JSX.Element
}

const AsContributor = (props: AsContributorProps) => {
  const { alternate = null, children } = props
  const assignedProjects = useProjects(STATES.ASSIGNED)
  const user: IUser = useSelector((state: any) => state.user)
  const { email, profile } = user
  const [ready, setReady] = useState<boolean>(false)

  useEffect(() => {
    if (user.profile) {
      setReady(true)
    }
  }, [user])

  if (email === undefined || !ready) {
    return alternate
  }

  const contributorProjects = assignedProjects.filter(
    (p: any) => p.owner.email === email || p.contributors?.includes(email)
  )
  if (contributorProjects.length === 0) {
    return alternate
  } else {
    if (!profile.roles.includes('contributor')) {
      setUserProfile(user.uid, { ...user.profile, roles: ['contributor'] })
    }
  }

  if (!profile?.roles?.includes('contributor')) {
    return alternate
  }

  return children
}

export default AsContributor
