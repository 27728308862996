import { Button } from 'antd'
import React from 'react'
import { useHistory } from 'react-router'
import ExternalApplication from '../../hosting/ExternalApplication'
import AsContributor from '../contributions/AsContributor'
import './BookKeeping.css'

const BookKeeping = () => {
  const host =
    process.env.NODE_ENV === 'development'
      ? 'http://localhost'
      : 'http://seventy-eight.s3-website.us-east-2.amazonaws.com'
  const port = process.env.NODE_ENV === 'development' ? 5001 : undefined
  const history = useHistory()
  return (
    <AsContributor
      alternate={
        <div className="fmay-text-button">
          You need to take ownership of a project
          <Button onClick={() => history.goBack()}>Go Back</Button>
        </div>
      }
    >
      <ExternalApplication
        appType="react"
        applicationName="Seventy Eight"
        host={host}
        port={port}
        manifestPath="/asset-manifest.json"
        branding="FMAY"
      />
    </AsContributor>
  )
}

export default BookKeeping
