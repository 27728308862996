import React from 'react'
import CustomEditor from './editor/CustomEditor'
const ConversationCard = (props) => {
  const { title, timeSpecification, content, width } = props
  return (
    <div
      style={{
        display: 'flex',
        borderStyle: 'solid',
        borderColor: 'rgba(30,144,255,.3)',
        borderRadius: 5,
        flexDirection: 'column',
        width: width,
        marginBottom: 20,
        alignItems: 'flex-start',
      }}
    >
      <div
        style={{
          borderBottomStyle: 'solid',
          borderBottomColor: 'rgba(30,144,255,.2)',
          backgroundColor: 'rgba(30,144,255,.1)',
          width: '100%',
          padding: 10,
          textAlign: 'left',
        }}
      >
        {typeof title === 'string' ? (
          <>
            <span style={{ fontWeight: 'bold' }}>{title}</span>
            <span>{timeSpecification}</span>
          </>
        ) : (
          title()
        )}
      </div>
      <div style={{ width: '100%' }}>
        <CustomEditor
          initialContent={content}
          toolbarHidden={true}
          readonly={true}
          hashtag={{
            trigger: '#',
          }}
        />
      </div>
    </div>
  )
}

export default ConversationCard
