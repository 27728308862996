import { getOctokits } from './GitAPI'

export const getUserMembership = async (organization, userToken) => {
  const { appKit, userKit } = await getOctokits(userToken)

  const owners = await appKit
    .request('GET /orgs/:org/teams/:team_slug/members', {
      org: organization,
      team_slug: 'Owners'
    })
    .then(data => data)

  const developers = await appKit
    .request('GET /orgs/:org/teams/:team_slug/members', {
      org: organization,
      team_slug: 'Developers'
    })
    .then(data => data)

  const userData = await userKit.users.getAuthenticated().then(data => data)

  const ownerMembership = owners.data.filter(
    m => m.login === userData.data.login
  )
  const developerMembership = developers.data.filter(
    m => m.login === userData.data.login
  )

  /**
   * This tells us if the user is a member of the organizations owner team.
   *
   * This allows them to see private repositories under the organization.
   * If the membership has a role of admin, then they get admin access
   */
  return {
    isAdmin: ownerMembership.length === 1,
    isMember: developerMembership.length === 1
  }
}

export const getUserRepositories = async (organization, token) => {
  if (token === undefined || token === '') {
    return []
  }
  const { appKit } = await getOctokits(undefined)
  const membership = await getUserMembership(organization, token)
  if (membership.isMember) {
    let repos = []
    if (membership.isAdmin) {
      repos = await appKit.repos
        .listForOrg({
          org: organization,
          type: 'private'
        })
        .then(result => {
          return result.data
        })
    } else {
      repos = await appKit
        .request('GET /orgs/{org}/teams/{team_slug}/repos', {
          org: organization,
          team_slug: 'Developers'
        })
        .then(result => result.data)
    }
    return repos
  }
  return []
}
