import { Card, List, Tabs } from 'antd'
import { MessageOutlined } from '@ant-design/icons'
import React, { useCallback, useEffect, useState } from 'react'
import ClientProjectIssue from './ClientProjectIssue'
import Text from 'antd/lib/typography/Text'
import { Divider } from 'antd'
import IProject from '../../model/types/IProject'
const { TabPane } = Tabs

export interface ClientIssuesProps {
  project: IProject
  issues: Map<string, any>
  toggleIssueAfterResolve: (issue: any) => void
  togglePrAfterResolve: (issue: any) => void
}

const ClientIssues = (props: ClientIssuesProps) => {
  const {
    project,
    issues,
    toggleIssueAfterResolve,
    togglePrAfterResolve,
  } = props
  const [viewingIssue, setViewingIssue] = useState(undefined)

  const [currentView, setCurrentView] = useState<any[]>([])
  const [tabKey, setTabKey] = useState(0)

  useEffect(() => {
    setViewingIssue(undefined)
  }, [project])

  const updateCurrentView = useCallback(
    (key: string) => {
      setCurrentView(
        issues.get(key).sort((a: any, b: any) => {
          return a.number > b.number ? -1 : a.number < b.number ? 1 : 0
        })
      )
    },
    [issues]
  )

  useEffect(() => {
    updateCurrentView('issues')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project])

  const toggleTabs = (tab: { key: string; label: string } | undefined) => {
    if (tab) {
      setViewingIssue(undefined)
      setTabKey(tabs.indexOf(tab))
      updateCurrentView(tab.key)
    }
  }

  const tabs: { key: string; label: string }[] = [
    { key: 'issues', label: 'Open Conversations' },
    { key: 'closedIssues', label: 'Closed Conversations' },
    { key: 'pullRequests', label: 'Current Resolutions' },
    { key: 'closedPullRequests', label: 'Resolved Resolutions' },
  ]

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
        padding: '10px 10px 30px',
      }}
    >
      <Tabs
        type="card"
        defaultActiveKey="0"
        onChange={(key) => {
          toggleTabs(tabs.find((t) => t.key === key))
        }}
      >
        {tabs.map((tab) => (
          <TabPane tab={tab.label} key={tab.key}></TabPane>
        ))}
      </Tabs>
      {viewingIssue ? (
        <ClientProjectIssue
          issue={viewingIssue}
          repository={project.repository_name}
          setViewingIssue={setViewingIssue}
          toggleIssueAfterResolve={toggleIssueAfterResolve}
          togglePrAfterResolve={togglePrAfterResolve}
          tabKey={tabKey}
        />
      ) : (
        <List
          bordered
          style={{ width: '100%', maxHeight: 'calc(100% - 250px)' }}
        >
          {currentView.map((issue) => {
            return (
              <Card
                key={issue.id}
                style={{ textAlign: 'left' }}
                size="small"
                hoverable
                onClick={() => setViewingIssue(issue)}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <div>
                    <Text type="secondary">#{issue.number}</Text>{' '}
                    <Divider type="vertical" /> {issue.title}
                  </div>
                  <div>
                    <MessageOutlined style={{ marginRight: 4 }} />
                    {issue.comments}
                  </div>
                </div>
              </Card>
            )
          })}
        </List>
      )}
    </div>
  )
}

export default ClientIssues
