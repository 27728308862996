import React from 'react'
import IProject from '../../model/types/IProject'
import IProjectStatus from '../../model/types/IProjectStatus'

export interface IClientProjectProgressProps {
  project: IProject
}

const ClientProjectProgress = (
  props: IClientProjectProgressProps
): JSX.Element => {
  const { project } = props
  return (
    <div style={{ width: '100%', fontSize: 16, fontWeight: 'bold' }}>
      Project Status:{' '}
      {project.status
        ? IProjectStatus[project.status]
        : IProjectStatus[IProjectStatus.Created]}
    </div>
  )
}

export default ClientProjectProgress
