import React, { useEffect } from 'react'
import Actions from '../../widgets/Actions'
import { useMediaQuery } from 'react-responsive'
import Navigation from '../../widgets/Navigation'
import './Landing.css'
import { useDispatch, useSelector } from 'react-redux'
import Footer from '../../widgets/Footer'
import { useHistory } from 'react-router'
import { setCurrentAction } from '../../model/navigation'
import GoHomeAction from '../../widgets/GoHomeAction'
import DefaultAction from '../../widgets/DefaultAction'
import { setUser } from '../../model/user'
import firebase from 'firebase'
import { getPledges, getProfile } from '../../external/FirebaseAPI'
import { RiseLoader } from 'react-spinners'

const TextLogo = (props) => {
  const { isSmall } = props
  const company = isSmall ? 'FMAY' : 'FMAY Software'
  return (
    <div
      style={{
        fontSize: 'clamp(100%, 1rem + 2vw, 24px)',
        fontFamily: 'Dancing Script',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          flexDirection: 'column',
        }}
      >
        {company}
      </div>
      {isSmall ? null : (
        <div
          style={{
            fontSize: 18,
            fontFamily: 'Dancing Script',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            width: '100%',
            lineHeight: 0,
            marginBottom: 5,
          }}
        >
          for all of us
        </div>
      )}
    </div>
  )
}

const titleStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontFamily: 'Dancing Script',
  fontSize: 'clamp(100%, 1rem + 2vw, 18px)',
  fontWeight: 800,
}
const Title = (props) => {
  return <div style={titleStyle}>{props.title}</div>
}

const SectionContainer = (props) => {
  const { defaultAction, hideFooter, alwaysShow } = props
  const storeUser = useSelector((state) => state.user)
  const dispatch = useDispatch()
  const isSmall = useMediaQuery({ maxWidth: 500 })
  const history = useHistory()
  const github = useSelector((state) => state.github)
  const isAdmin = github.membership?.isAdmin
  const isDirect =
    window.location.hostname.startsWith('client') ||
    window.location.hostname.startsWith('contributor')
  const loadingProjects = useSelector((state) => state.projects.loading)
  const loadingRepositories = useSelector((state) => state.repositories.loading)

  const directed = history.location.state && history.location.state.directed

  useEffect(() => {
    dispatch(setCurrentAction(directed ? DefaultAction : GoHomeAction))
  }, [directed, dispatch])

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      // NOTE: we may want to consider separating client logic auth and this observer
      if (user && !isDirect) {
        getProfile(user).then((profile) => {
          getPledges(user).then((pledges) => {
            dispatch(
              setUser({
                displayName: user.displayName ? user.displayName : 'Not Set',
                email: user.email ? user.email : 'Not Set',
                uid: user.uid,
                photoURL: user.photoURL,
                providerId: user.providerData[0]
                  ? user.providerData[0].providerId
                  : '',
                pledges: pledges,
                profile: profile,
              })
            )
          })
        })
      } else {
        if (user) {
          getProfile(user).then((profile) => {
            getPledges(user).then((pledges) => {
              dispatch(
                setUser({
                  displayName: user.displayName ? user.displayName : 'Not Set',
                  email: user.email ? user.email : 'Not Set',
                  uid: user.uid,
                  photoURL: user.photoURL,
                  providerId: user.providerData[0]
                    ? user.providerData[0].providerId
                    : '',
                  pledges: pledges,
                  profile: profile,
                })
              )
            })
          })
        } else {
          // logged out
          dispatch(setUser(null))
        }
      }
    })
  }, [dispatch, isDirect])

  return (
    <div className="fmay-software--section-container">
      <header className="App-header">
        <div className="logo">
          <TextLogo title={props.title} isSmall={isSmall} />
        </div>
        <div className="actions">
          <Actions defaultAction={defaultAction} />
        </div>
        <div className="main-navigation">
          <Navigation user={storeUser} admin={isAdmin} />
        </div>
      </header>
      <div className="section">
        <Title {...props} />
        {!alwaysShow && (loadingProjects || loadingRepositories) ? (
          <div style={{ margin: 20 }}>
            <RiseLoader color="dodgerblue" width={12} loading={true} />
            <div style={{ color: 'dodgerblue', fontSize: 18, marginTop: 10 }}>
              Loading Projects
            </div>
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              justifyContent: 'flex-start',
              flexDirection: 'column',
            }}
          >
            {props.children}
          </div>
        )}
      </div>
      {hideFooter ? null : <Footer isDirect={isDirect} />}
    </div>
  )
}

export default SectionContainer
