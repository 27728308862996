import { IssuesOpenedEvent } from '@octokit/webhooks-types'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getIssueComments,
  getIssuesForRepository,
} from '../../../external/GitIssues'
import { updateProject } from '../../../model/projects'
import { IComment } from '../../../model/types/IComment'
import { IModelRoot } from '../../../model/types/IModelRoot'
import IProject from '../../../model/types/IProject'
import { IRecentActivity } from '../../../model/types/IRecentActivity'
import { useClientListener } from './useClientListener'

export const useRecentActivity = (projects: IProject[]) => {
  const dispatch = useDispatch()

  const [recentActivity, setRecentActivity] = useState<IRecentActivity[]>([])

  const issuesOpened = (event: IssuesOpenedEvent) => {
    const project = projects.find(
      (p) => p.repository_name === event.repository.name
    )
    if (project) {
      const updatedIssues = [event.issue, ...project.issues]
      const updatedProject = { ...project, issues: updatedIssues }
      // add to recent activity
      setRecentActivity([
        ...recentActivity,
        {
          title: event.issue.title,
          created_at: new Date(event.issue.created_at),
          number: event.issue.number,
          body: event.issue.body || '',
        },
      ])
      dispatch(updateProject(updatedProject))
    }
  }
  useClientListener(issuesOpened)

  const findUpdatesFrom =
    useSelector(
      (state: IModelRoot) =>
        state.user.profile.findUpdatesFrom || new Date().getTime()
    ) - 3600000 // within the last hour

  console.log(new Date(findUpdatesFrom))

  useEffect(() => {
    if (projects && projects.length > 0) {
      projects.forEach(async (project) => {
        const result = await getIssuesForRepository(
          'FMAY-Software',
          project.repository_name,
          'open',
          new Date(findUpdatesFrom).toISOString()
        )
        const issues = result.data
        let newActivity: IRecentActivity[] = []
        for (let issue of issues) {
          if (new Date(issue.created_at) > new Date(findUpdatesFrom)) {
            newActivity = [
              ...newActivity,
              {
                title: issue.title,
                created_at: new Date(issue.created_at),
                number: issue.number,
                body: issue.body,
              },
            ]
          }
          const comments = await getIssueComments(
            issue,
            new Date(findUpdatesFrom).toISOString()
          )
          newActivity = [
            ...newActivity,
            ...comments.map((c: IComment) => {
              return {
                title: issue.title,
                number: issue.number,
                created_at: new Date(c.created_at),
                body: c.body,
              }
            }),
          ]
          setRecentActivity(newActivity)
        }
      })
    }
  }, [projects, findUpdatesFrom])

  return {
    recentActivity,
  }
}
