import { Row } from 'antd'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router'
import { getLatestConfiguration } from '../../external/GitProjects'
import { setCurrentAction } from '../../model/navigation'
import { updateProject } from '../../model/projects'
import DefaultAction from '../../widgets/DefaultAction'
import GoHomeAction from '../../widgets/GoHomeAction'
import SectionContainer from '../main/SectionContainer'
import useProjects from './hooks/useProjects'
import Project from './Project'
import ProjectInformation from './ProjectInformation'
import ProjectSearch from './ProjectSearch'

const CategorizedProjects = (props) => {
  const { setCurrentAction, projectState, mainTitle, updateProject } = props
  const [selectedProject, setSelectedProject] = useState(undefined)
  const [search, setSearch] = useState('')
  const [title, setTitle] = useState(mainTitle)
  const history = useHistory()
  const [landing, setLanding] = useState(true)
  const projects = useProjects(projectState)

  if (history.location.search !== '' && landing) {
    setSearch(decodeURI(history.location.search.replace('?', '')))
    setLanding(false)
  }

  var directed = false
  if (history.location.state && history.location.state.directed) {
    directed = true
  }

  const handleProjectSelected = (project) => {
    if (project.repository_name) {
      getLatestConfiguration(
        'FMAY-Software',
        project.repository_name,
        project.home,
        project.private
      ).then((p) => {
        if (p !== undefined) {
          project = p
          updateProject(p)
          setSelectedProject(p)
        } else {
          setSelectedProject(project)
        }
      })
    }

    const projectTitle = project.name + (project.private ? ' (private)' : '')
    setTitle(projectTitle)
    setCurrentAction({
      ...DefaultAction,
      action: () => {
        setSelectedProject(undefined)
        setTitle(mainTitle)
        setCurrentAction(directed ? DefaultAction : GoHomeAction)
      },
    })
    setSelectedProject(project)
  }

  const filteredProjects = projects.filter(
    (p) =>
      search === '' ||
      p.name.toLowerCase().includes(search.toLowerCase()) ||
      p.description.toLowerCase().includes(search.toLowerCase())
  )
  filteredProjects.sort((a, b) =>
    a.name.toLowerCase().localeCompare(b.name.toLowerCase())
  )

  return (
    <SectionContainer title={title}>
      <div style={{ paddingBottom: 50 }}>
        {selectedProject?.name ? (
          <ProjectInformation project={selectedProject} />
        ) : (
          <>
            <ProjectSearch search={search} setSearch={setSearch} />
            <Row
              type="flex"
              style={{
                alignItems: 'center',
                justifyContent: 'center',
                flexWrap: 'wrap',
                marginLeft: 0,
                marginRight: 0,
              }}
              gutter={(16, 16)}
              align="middle"
            >
              {filteredProjects.map((p, index) => (
                <Project
                  key={index}
                  title={p.name}
                  description={p.description}
                  onClick={() => handleProjectSelected(p)}
                  isPrivate={p.private}
                />
              ))}
            </Row>
          </>
        )}
      </div>
    </SectionContainer>
  )
}

const mapDispatchToProps = {
  setCurrentAction,
  updateProject,
}

export default connect(null, mapDispatchToProps)(CategorizedProjects)
