import React, { useState } from 'react'
import { getSignedURL } from '../../external/S3API'
import { Button, Card, Modal, Table } from 'antd'
import OwnerInfoCard from '../contributions/OwnerInfoCard'
import PledgorInfoCard from '../contributions/PledgorInfoCard'
import Pledge from '../user/pledgor/Pledge'
import ClientProjectProgress from './ClientProjectProgress'
import { sendProjectOwnershipRequest } from '../../external/GitAPI'
import { useSelector } from 'react-redux'
import CustomEditor from '../../widgets/editor/CustomEditor'
import '../../widgets/editor/editor-styles.css'

const ProjectContents = (props) => {
  const { project } = props
  const [showOwnerInfo, setShowOwnerInfo] = useState(false)
  const [showPledgorInfo, setShowPledgorInfo] = useState(false)
  const [showPledgeEntry, setShowPledgeEntry] = useState(false)
  const [
    showRequestOwnershipResponse,
    setShowRequestOwnershipResponse,
  ] = useState(false)
  const user = useSelector((state) => state.user)

  const downloadS3 = (url) => {
    let link = document.createElement('a')
    link.href = getSignedURL(url)
    link.click()
  }

  const followLink = (url) => {
    let link = document.createElement('a')
    link.href = url
    link.click()
  }

  const handleDownload = (url) => {
    if (url.startsWith('S3:/')) {
      downloadS3(url)
    }
  }

  const handleProjectRequest = () => {
    sendProjectOwnershipRequest('FMAY-Software', user, project)
    setShowRequestOwnershipResponse(false)
  }

  const CustomBorderButton = (props) => {
    const { action, title, url, disabled } = props
    return (
      <Button
        key={title}
        size="small"
        disabled={disabled}
        onClick={action ? action : () => followLink(url)}
        style={{ ...props.style, borderColor: 'dodgerblue' }}
      >
        {title}
      </Button>
    )
  }

  const deliverables = project.deliverables.map((d, i) => {
    return { ...d, key: i }
  })

  const isClient = project.clients && project.clients.length > 0

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: props.justifyContent ? props.justifyContent : 'center',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <Modal
        visible={!isClient && showOwnerInfo}
        onOk={() => setShowOwnerInfo(false)}
        closable={false}
        title={
          project.owner
            ? `${project.owner.first_name} ${project.owner.last_name}`
            : 'No Owner'
        }
        footer={[<Button onClick={() => setShowOwnerInfo(false)}>Done</Button>]}
      >
        <OwnerInfoCard owner={project.owner} />
      </Modal>
      <Modal
        visible={!isClient && showRequestOwnershipResponse}
        onOk={() => setShowRequestOwnershipResponse(false)}
        closable={false}
        title={'Request Sent'}
        footer={[<Button onClick={() => handleProjectRequest()}>OK</Button>]}
      >
        You request has been sent to the project owner.
      </Modal>
      <Modal
        visible={showPledgorInfo}
        onOk={() => setShowPledgorInfo(false)}
        closable={false}
        title="Pledgors"
        footer={[
          <Button onClick={() => setShowPledgorInfo(false)}>Done</Button>,
        ]}
      >
        <PledgorInfoCard project={project} />
      </Modal>
      {isClient ? <ClientProjectProgress project={project} /> : null}
      <Card
        style={{
          borderColor: 'dodgerblue',
          width:
            props.justifyContent && props.justifyContent === 'stretch'
              ? '100%'
              : undefined,
        }}
        bodyStyle={{ padding: 10 }}
        actions={
          isClient
            ? null
            : [
                <Button
                  key="contact"
                  size="small"
                  style={{ marginLeft: 25 }}
                  onClick={() => setShowRequestOwnershipResponse(true)}
                >
                  Request Ownership
                </Button>,
                <Button
                  key="contact"
                  size="small"
                  style={{ marginLeft: 25 }}
                  onClick={() => setShowOwnerInfo(true)}
                >
                  Contact Owner
                </Button>,
              ]
        }
      >
        <div
          className="editor-displayonly"
          style={{
            maxWidth:
              props.justifyContent && props.justifyContent === 'stretch'
                ? undefined
                : 500,
          }}
        >
          <CustomEditor
            displayOnly={true}
            renderMarkdown={true}
            initialContent={project.description}
          />
        </div>
        <div style={{ marginTop: 20 }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              marginTop: 15,
              marginBottom: 15,
              flexWrap: 'wrap',
            }}
          >
            {project.home ? (
              <CustomBorderButton
                style={{ marginRight: 5 }}
                title="View on GitHub"
                url={project.home}
              />
            ) : null}
            {project.repository_name ? (
              <CustomBorderButton
                style={{ marginRight: 5 }}
                title="Project Specification"
                url={`https://fmay-software.github.io/${project.repository_name}/`}
              />
            ) : null}
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              marginTop: 15,
              marginBottom: 15,
            }}
          >
            {project.demonstration ? (
              <CustomBorderButton
                style={{ marginRight: 5 }}
                title="Demonstration"
                disabled={project.demonstration.url === ''}
                url={project.demonstration.url}
              />
            ) : null}
            {project.demonstrations ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    fontSize: '1.3em',
                    fontFamily: 'Dancing Script',
                  }}
                >
                  Demonstrations
                </div>
                <Table
                  pagination={false}
                  size="small"
                  dataSource={project.demonstrations}
                  style={{ width: '100%' }}
                  columns={[
                    { title: 'Name', dataIndex: 'name', key: 'name' },
                    {
                      title: 'Link',
                      dataIndex: 'url',
                      key: 'url',
                      render: (url) => {
                        return url === 'None' ? null : (
                          <Button size="small" href={url}>
                            View
                          </Button>
                        )
                      },
                    },
                  ]}
                />
              </div>
            ) : null}
            {!isClient && (
              <CustomBorderButton
                style={{ marginRight: 5 }}
                title="Pledgors"
                action={() => setShowPledgorInfo(true)}
              />
            )}
            {!isClient && !showPledgeEntry ? (
              <CustomBorderButton
                title="Pledge"
                action={() => setShowPledgeEntry(true)}
              />
            ) : (
              !isClient && (
                <Pledge
                  setShowPledgeEntry={setShowPledgeEntry}
                  project={project}
                />
              )
            )}
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              fontSize: '1.3em',
              fontFamily: 'Dancing Script',
            }}
          >
            Downloads
          </div>
          {deliverables.length === 1 && deliverables[0].url === 'None' ? (
            <div style={{ textAlign: 'start' }}>None</div>
          ) : (
            <Table
              pagination={false}
              size="small"
              dataSource={deliverables}
              columns={[
                { title: 'Target', dataIndex: 'target', key: 'target' },
                {
                  title: '',
                  dataIndex: 'url',
                  key: 'url',
                  render: (url) => {
                    return url === 'None' ? null : (
                      <Button size="small" onClick={() => handleDownload(url)}>
                        Download
                      </Button>
                    )
                  },
                },
              ]}
            />
          )}
        </div>
      </Card>
    </div>
  )
}
export default ProjectContents
