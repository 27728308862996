import { IUser } from './types/auth/IUser'

export const USER = '_user'

/** Reducers */
export const user = (state = {}, action: { type: string; payload: IUser }) => {
  switch (action.type) {
    case USER:
      return action.payload
    default:
      return state
  }
}

/** Actions */
export const setUser = (user: IUser) => {
  return {
    type: USER,
    payload: user,
  }
}
