import { createRepository } from '../../../external/GitUpdateAPI'

/**
 * We are given a name and description
 * We need to create
 *
 *  * A github repository
 *  * Initial project structure
 *  * A configuration file
 */
export const createProjectOnGithub = async (user, values, isPrivate) => {
  // create the github repository
  const { name, description, about } = values
  return createRepository(
    'FMAY-Software',
    user,
    name,
    description,
    values,
    about,
    isPrivate
  )
}
