import { getOctokits } from './GitAPI'

export const createComment = async (
  organization,
  repository,
  issue_number,
  message
) => {
  const { appKit } = await getOctokits(undefined)
  return appKit.issues.createComment({
    owner: organization,
    repo: repository,
    body: message,
    issue_number: issue_number,
  })
}

export const getIssueComments = async (issue, since) => {
  const { appKit } = await getOctokits(undefined)
  return appKit
    .request({ url: issue.comments_url, since: since })
    .then((res) => res.data)
}

export const createIssue = async (organization, repository, title, message) => {
  const { appKit } = await getOctokits(undefined)
  const issue = await appKit.issues.create({
    owner: organization,
    repo: repository,
    title: title,
    body: message,
  })
  return issue.data
}

export const toggleIssueState = async (
  organization,
  repository,
  issue_number,
  state
) => {
  const { appKit } = await getOctokits(undefined)
  const issue = await appKit.issues.update({
    owner: organization,
    repo: repository,
    issue_number: issue_number,
    state: state,
  })
  return issue.data
}

export const getIssuesForRepository = async (
  organization,
  repository,
  state,
  since
) => {
  const { appKit } = await getOctokits(undefined)
  return appKit.issues.listForRepo({
    owner: organization,
    repo: repository,
    state: state,
    since: since,
  })
}

export const getPullRequestsForRepository = async (
  organization,
  repository,
  state
) => {
  const { appKit } = await getOctokits(undefined)
  return appKit.pulls.list({
    owner: organization,
    repo: repository,
    state: state,
  })
}

export const togglePullRequest = async (
  organization,
  repository,
  pullNumber,
  state
) => {
  const { appKit } = await getOctokits(undefined)
  return appKit.pulls.update({
    owner: organization,
    repo: repository,
    pull_number: pullNumber,
    state: state,
  })
}

export const mergePullRequest = async (
  organization,
  repository,
  pullNumber
) => {
  const { appKit } = await getOctokits(undefined)
  return appKit.pulls.merge({
    owner: organization,
    repo: repository,
    pull_number: pullNumber,
  })
}
