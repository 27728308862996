import { Application } from './types/ApplicationTypes'

export const SET_EXTERNAL_APP = '_external_apps_add'

/** Reducers */
export const external = (state: Application[] = [], action: any) => {
  switch (action.type) {
    case SET_EXTERNAL_APP:
      const otherApps = state.filter((a) => a.name !== action.payload.name)
      const newApps = [...otherApps, action.payload]
      return newApps
    default:
      return state
  }
}

/** Actions */
export const setExternalApplication = (application: Application) => {
  return {
    type: SET_EXTERNAL_APP,
    payload: application,
  }
}
