import React from 'react'
import SectionContainer from '../sections/main/SectionContainer'

const AccessDenied = (prop) => {
  return (
    <SectionContainer alwaysShow={true}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          height: '60vh',
        }}
      >
        <img src="/noaccess.png" alt="denied" style={{ width: 400 }} />
        <p>This area requires top secret clearance!</p>
      </div>
    </SectionContainer>
  )
}

export default AccessDenied
