import {
  ArrowLeftOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
} from '@ant-design/icons'
import { Button, Divider, Popconfirm } from 'antd'
import Text from 'antd/lib/typography/Text'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  createComment,
  getIssueComments,
  mergePullRequest,
  toggleIssueState,
  togglePullRequest,
} from '../../external/GitIssues'
import Comment from './Comment'
import PostComment from './PostComment'
const ClientProjectIssue = (props) => {
  const {
    issue,
    repository,
    setViewingIssue,
    toggleIssueAfterResolve,
    togglePrAfterResolve,
    tabKey,
  } = props
  const [comments, setComments] = useState([])
  const [postingComment, setPostingComment] = useState(false)
  const [sortAsc, setSortAsc] = useState(true)
  const account = useSelector((state) => state.user.email)

  const parseComment = (comment) => {
    let author = 'FMAY Software'
    const parts = comment.body.split('<:::>')
    if (parts.length === 2) {
      author = parts[0]
      comment.body = parts[1]
    }
    return {
      author: author,
      comment: comment,
    }
  }

  useEffect(() => {
    getIssueComments(issue).then((comments) => {
      // map these two supported object
      const supported = comments.map((c) => {
        return parseComment(c)
      })
      sortComments(supported, true)
      setComments(supported)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const sortComments = (comments, ascending) => {
    // order by date
    comments.sort((a, b) => {
      const adate = a.comment.created_at
      const bdate = b.comment.created_at
      if (adate > bdate) {
        return ascending ? -1 : 1
      }
      if (adate < bdate) {
        return ascending ? 1 : -1
      }
      return 0
    })
  }

  const handleCommentSort = () => {
    sortComments(comments, !sortAsc)
    setSortAsc(!sortAsc)
  }

  const addNewComment = (newComment) => {
    createComment(
      'FMAY-Software',
      repository,
      issue.number,
      `${account}<:::>${newComment}`
    ).then((comment) => {
      //  to close or not to  close
      setPostingComment(false)
      const supported = parseComment(comment.data)
      setComments([...comments, supported])
    })
  }

  const _toggleIssueState = (state) => {
    toggleIssueState('FMAY-Software', repository, issue.number, state).then(
      (result) => {
        toggleIssueAfterResolve(result)
        setViewingIssue(undefined)
      }
    )
  }

  const _togglePullRequest = (state) => {
    togglePullRequest('FMAY-Software', repository, issue.number, state).then(
      () => {
        togglePrAfterResolve({ ...issue, state })
        setViewingIssue(undefined)
      }
    )
  }

  const _mergePullRequest = () => {
    mergePullRequest('FMAY-Software', repository, issue.number).then(() => {
      togglePrAfterResolve({ ...issue, state: 'closed' })
      setViewingIssue(undefined)
    })
  }

  const isPrTab = tabKey === 1
  const isOpenTab = tabKey === 0

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          width: '100%',
        }}
      >
        <Button
          onClick={() => setViewingIssue(undefined)}
          style={{ fontSize: 18, marginRight: 20 }}
          icon={<ArrowLeftOutlined />}
        />
        <h2>
          <Text type="secondary">#{issue.number}</Text>{' '}
          <Divider type="vertical" /> {issue.title}
        </h2>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 10,
          width: '100%',
        }}
      >
        <div>
          {tabKey !== 3 && (
            <>
              <Button
                style={{ marginRight: 5 }}
                onClick={() => setPostingComment(true)}
              >
                Add Comment
              </Button>
              {isPrTab && (
                <Popconfirm
                  title={'Are you sure you want to accept this resolution?'}
                  onConfirm={() => _mergePullRequest()}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button style={{ marginRight: 5 }}>Accept Resolution</Button>
                </Popconfirm>
              )}
              <Popconfirm
                title={
                  isPrTab
                    ? 'Are you sure you want to reject this resolution?'
                    : isOpenTab
                    ? 'Are you sure you want to resolve this conversation?'
                    : 'Are you sure you want to reopen this conversation?'
                }
                onConfirm={() =>
                  isPrTab
                    ? _togglePullRequest('closed')
                    : _toggleIssueState(isOpenTab ? 'closed' : 'open')
                }
                okText="Yes"
                cancelText="No"
              >
                <Button danger={isOpenTab || isPrTab}>
                  {isPrTab
                    ? 'Reject Resolution'
                    : isOpenTab
                    ? `Resolve Conversation`
                    : 'Reopen Conversation'}
                </Button>
              </Popconfirm>
            </>
          )}
        </div>
        <div>
          <Button
            icon={
              sortAsc ? <SortDescendingOutlined /> : <SortAscendingOutlined />
            }
            onClick={() => handleCommentSort()}
          >
            Sort
          </Button>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
          width: '100%',
        }}
      >
        {postingComment ? (
          <PostComment
            setPostingComment={setPostingComment}
            show={postingComment}
            addNewComment={addNewComment}
          />
        ) : null}
        <div>
          {comments.map((c, i) => (
            <Comment
              date={c.comment.created_at}
              body={c.comment.body}
              author={c.author}
              key={i}
            />
          ))}
          {issue.body && (
            <Comment
              date={issue.created_at}
              author="FMAY Software"
              body={issue.body}
            />
          )}
        </div>
      </div>
    </div>
  )
}
export default ClientProjectIssue
