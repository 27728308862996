import React from 'react'
import { Menu, Spin } from 'antd'
import { ProjectOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import IProject from '../../model/types/IProject'
import { IModelRoot } from '../../model/types/IModelRoot'
import RecentActivity from './RecentActivity'

export interface ClientMenuProps {
  selectedProject: IProject | undefined
  setSelectedProject: (project: IProject | undefined) => void
  projects: IProject[]
}

const ClientMenu = (props: ClientMenuProps) => {
  const { selectedProject, setSelectedProject, projects } = props
  const selectedProjectKey = selectedProject ? selectedProject.name : ''
  const loading = useSelector((state: IModelRoot) => state.projects.loading)

  return (
    <div className="client-menu">
      <div className="client-menu-header">Recent Activity</div>
      <RecentActivity projects={projects} />
      <div className="client-menu-header">Current Projects</div>
      <Menu
        selectedKeys={[selectedProjectKey]}
        mode="inline"
        inlineCollapsed={false}
      >
        {loading ? (
          <Menu.Item className="client-menu-loading">
            <Spin spinning={true} />
          </Menu.Item>
        ) : undefined}
        {projects.map((p) => (
          <Menu.Item
            onClick={() =>
              setSelectedProject(
                !selectedProject
                  ? p
                  : p.name !== selectedProject.name
                  ? p
                  : undefined
              )
            }
            key={p.name}
            icon={<ProjectOutlined />}
          >
            {p.name}
          </Menu.Item>
        ))}
      </Menu>
      <div className="client-menu-header">Completed Projects</div>
      <div className="client-menu-entry">None</div>
    </div>
  )
}
export default ClientMenu
