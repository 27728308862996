import { combineReducers } from 'redux'

export const CURRENT_ACTION = '_navigation_action'

/** Reducers */
export const navigationAction = (state = {}, action) => {
  switch (action.type) {
    case CURRENT_ACTION:
      return action.payload ? action.payload : {}
    default:
      return state
  }
}

export const navigationReducer = combineReducers({
  action: navigationAction
})

/** Actions */
export const setCurrentAction = action => {
  return {
    type: CURRENT_ACTION,
    payload: action
  }
}
