import React from 'react'
import IProject from '../../model/types/IProject'

export interface ClientProjectContributorsProps {
  project: IProject | undefined
  projects: IProject[]
}
const ClientProjectContributors = (props: ClientProjectContributorsProps) => {
  const { project, projects } = props

  let contributors = project
    ? project.contributors
    : projects.map((p) => p.contributors).flat()
  // use empty array if no contributors yet
  contributors = contributors ? contributors : []

  return (
    <div className="client-menu-contributors">
      {contributors.map((c, i) => (
        <a
          key={`${c}-${i}`}
          href={`mailto:${c}?subject=FMAY+Client+communication${
            project ? `: ${project.name}` : ''
          }`}
        >
          {c}
        </a>
      ))}
    </div>
  )
}

export default ClientProjectContributors
