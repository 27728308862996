export const CREDENTIAL = '_credential'

export const credential = (state = {}, action: any) => {
  switch (action.type) {
    case CREDENTIAL:
      return action.payload
    default:
      return state
  }
}

export const setCredential = (credential: any) => {
  return {
    type: CREDENTIAL,
    payload: credential,
  }
}
