import React from 'react'

const PledgorInfoCard = props => {
  const { project } = props
  const pledgors = project.pledgors

  return (
    <div
      style={{
        overflow: 'auto',
        maxHeight: 250,
        paddingLeft: 15,
        paddingRight: 15
      }}
    >
      {pledgors && pledgors.length > 0 ? (
        pledgors.map(p => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: 15
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'center',
                flexDirection: 'column'
              }}
            >
              <p style={{ margin: 0 }}>{p.id}</p>
              {p.company ? <p style={{ margin: 0 }}>{p.company}</p> : null}
              {p.contact ? (
                <a href={`mailto:${p.contact}`}>{p.contact}</a>
              ) : null}
            </div>
            <p style={{ margin: 0 }}>${p.pledged}</p>
          </div>
        ))
      ) : (
        <p>None</p>
      )}
    </div>
  )
}

export default PledgorInfoCard
