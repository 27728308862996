const GITHUB_TOKEN = '_github_token'
export const GITHUB_ACCESS_TOKEN = '_github_user_token'
export const GITHUB_KEY = '_github_key'
const GITHUB_EXPIRATION = '_github_expiration'
export const GITHUB_MEMBERSHIP = '_github_membership'

/** Reducers */
export const github = (state = { key: '' }, action) => {
  switch (action.type) {
    case GITHUB_TOKEN:
      return { ...state, token: action.payload }
    case GITHUB_KEY:
      return { ...state, key: action.payload }
    case GITHUB_EXPIRATION:
      return { ...state, expiration: action.payload }
    case GITHUB_ACCESS_TOKEN:
      return { ...state, userToken: action.payload }
    case GITHUB_MEMBERSHIP:
      return { ...state, membership: action.payload }
    default:
      return state
  }
}

/** Actions */
export const setGHToken = token => {
  return {
    type: GITHUB_TOKEN,
    payload: token
  }
}
export const setGHExpiration = exp => {
  return {
    type: GITHUB_EXPIRATION,
    payload: exp
  }
}

export const setGHKey = key => {
  return {
    type: GITHUB_KEY,
    payload: key
  }
}
