import { Octokit } from '@octokit/rest'
import { getOctokits, getToken, USER_AGENT } from './GitAPI'
import {
  getIssuesForRepository,
  getPullRequestsForRepository,
} from './GitIssues'
import { commitChangeSet } from './GitUpdateAPI'
import { getUserRepositories } from './GitUser'

export const getRepositories = async (organization, userToken, client) => {
  const token = await getToken()

  const octokit = new Octokit({
    auth: token,
    userAgent: USER_AGENT,
  })

  const publicRepositories = await octokit.repos
    .listForOrg({
      org: organization,
      type: 'public',
    })
    .then(({ data }) => data)
  let clientRepositories = []
  if (client) {
    const privateRepositories = await octokit.repos
      .listForOrg({
        org: organization,
        type: 'private',
      })
      .then(({ data }) => data)
    for (let i = 0; i < privateRepositories.length; i++) {
      const project = await getProjects(
        organization,
        privateRepositories[i].name
      )
      if (project.clients?.includes(client)) {
        clientRepositories.push(privateRepositories[i])
      }
    }
  }

  const userRepositories = await getUserRepositories(organization, userToken)
  return [...clientRepositories, ...publicRepositories, ...userRepositories]
}

export const getProjects = async (organization, repository) => {
  const { appKit } = await getOctokits(undefined)
  let config = ''
  try {
    await appKit.repos.getContent({
      method: 'HEAD',
      owner: organization,
      repo: repository,
      path: 'configuration.json',
    })
    config = await appKit.repos
      .getContent({
        owner: organization,
        repo: repository,
        path: 'configuration.json',
      })
      .then((result) => {
        return Buffer.from(result.data.content, 'base64').toString()
      })
  } catch (error) {
    // ignored
  }

  const projectInfo =
    config === '' || config === undefined ? '' : JSON.parse(config)
  if (projectInfo !== '') {
    // append issues to project
    const openIssues = await getIssuesForRepository(
      organization,
      repository,
      'open'
    )
    const closedIssues = await getIssuesForRepository(
      organization,
      repository,
      'closed'
    )

    const pullRequests = await getPullRequestsForRepository(
      organization,
      repository,
      'all'
    )

    projectInfo.issues = openIssues.data.filter(
      (row) => !Object.keys(row).includes('pull_request')
    )
    //Not sure if we care if these closedIssues are also pull requests?
    projectInfo.closedIssues = closedIssues.data
    projectInfo.pullRequests = pullRequests.data.filter(
      ({ state }) => state === 'open'
    )
    projectInfo.closedPullRequests = pullRequests.data.filter(
      ({ state }) => state === 'closed'
    )
    const assignee =
      openIssues.data.length > 0
        ? openIssues.data[openIssues.data.length - 1].assignee
        : undefined
    if (assignee) {
      const assigneeUser = await appKit.request({
        url: assignee.url,
      })
      projectInfo.assignee = assigneeUser.data
    }
  }
  return projectInfo
}

export const updateProjectConfiguration = async (organization, project) => {
  commitChangeSet(
    organization,
    project.repository_name,
    'master',
    'master',
    { 'configuration.json': JSON.stringify(project, null, '\t') },
    'Update FMAY project configuration.'
  )
}

export const getLatestConfiguration = async (
  organization,
  repository,
  repository_url,
  isPrivate
) => {
  const project = await getProjects(organization, repository)
  if (project === '') {
    return undefined
  }
  project.repository_name = repository
  project.home = repository_url
  project.private = isPrivate
  return project
}
