import React from 'react'
import { Editor, EditorProps } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import htmlToDraft from 'html-to-draftjs'
import { ContentState, convertToRaw, convertFromRaw } from 'draft-js'
import { getSignedURL } from '../../external/S3API'
import { uploadImageCallBack } from './helper-functions'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import './editor-styles.css'
import { markdownToDraft } from 'markdown-draft-js'

export interface CustomEditorProps {
  initialContent?: string
  displayOnly?: boolean
  renderMarkdown?: boolean
}

const CustomEditor = (props: EditorProps & CustomEditorProps) => {
  const { initialContent, displayOnly, renderMarkdown, ...rest } = props

  let rawHtml = undefined
  if (initialContent) {
    const imageSupportedContent = recreateS3Links(initialContent)
    let contentState = undefined
    if (renderMarkdown) {
      const draftContent = markdownToDraft(imageSupportedContent)
      contentState = convertFromRaw(draftContent)
    } else {
      const draftContent = htmlToDraft(imageSupportedContent)
      contentState = ContentState.createFromBlockArray(
        draftContent.contentBlocks,
        undefined
      )
    }
    if (contentState) rawHtml = convertToRaw(contentState)
  }
  return (
    <Editor
      editorClassName="editor"
      readOnly={displayOnly}
      toolbar={{
        image: {
          previewImage: true,
          uploadCallback: uploadImageCallBack,
        },
      }}
      hashtag={{
        trigger: '#',
      }}
      contentState={rawHtml}
      {...rest}
    />
  )
}

/**
 * Convert intial content to use newly created S3 signed urls
 * @param initialContent persisted issue data
 * @returns new string with valid S3 link
 */
const recreateS3Links = (initialContent: string): string => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(initialContent, 'text/html')
  const images = doc.getElementsByTagName('img')
  for (let i = 0; i < images.length; i++) {
    const img = images[i]
    const newUrl = getSignedURL(`client_images/${extractImageName(img.src)}`)
    img.src = newUrl
  }
  return doc.body.innerHTML
}

const extractImageName = (line: string): string => {
  const start = /https.*client_images\//g
  const end = /\?.*/g
  const imageName = line.replace(start, '').replace(end, '')
  return decodeURI(imageName).replaceAll(' ', '+')
}

export default CustomEditor
