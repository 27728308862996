const AWS = require('aws-sdk')

const s3 = new AWS.S3({
  signatureVersion: 'v4',
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: 'us-east-2',
})

const writeS3 = new AWS.S3({
  getSignedURL: 'v4',
  accessKeyId: process.env.REACT_APP_AWS_S3_WRITE_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_S3_WRITE_ACCESS_KEY,
  region: 'us-east-2',
})

export const getSignedURL = (path) => {
  path = path.replace('S3:/fmay/', '')
  return s3.getSignedUrl('getObject', {
    Bucket: 'fmay',
    Key: path,
    Expires: 300,
  })
}

export const uploadFile = (path, file) => {
  writeS3
    .putObject({
      Bucket: 'fmay',
      Key: path,
      Body: file,
    })
    .promise()
}

export const getGHKey = async () => {
  const data = await s3
    .getObject({
      Bucket: 'fmay',
      Key: 'GitHub/fmay-software.key',
    })
    .promise()
    .then((data) => data)
  return new TextDecoder().decode(data.Body)
}
