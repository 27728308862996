import React, { useEffect, useState } from 'react'
import SectionContainer from '../main/SectionContainer'
import Login from '../registered/Login'
import { useDispatch, useSelector } from 'react-redux'
import ClientProjects from './ClientProjects'
import { CURRENT_ACTION } from '../../model/navigation'
import { getCurrentUser, getProfile } from '../../external/FirebaseAPI'
import './client.css'
import { setUser } from '../../model/user'
import IProject from '../../model/types/IProject'
import { IModelRoot } from '../../model/types/IModelRoot'

const Client = () => {
  const user = useSelector((state: IModelRoot) => state.user)
  const [selectedProject, setSelectedProject] = useState<IProject | undefined>(
    undefined
  )

  const dispatch = useDispatch()
  useEffect(() => {
    if (!user) {
      // we may be logged in but handling a hard refresh
      const current = getCurrentUser()
      if (current) {
        // get profile and set the user in the store
        getProfile(current).then((profile) => {
          dispatch(
            setUser({
              displayName: current.displayName
                ? current.displayName
                : 'Not Set',
              email: current.email ? current.email : 'Not Set',
              uid: current.uid,
              photoURL: current.photoURL ?? '',
              providerId: current.providerData[0]
                ? current.providerData[0].providerId
                : '',
              profile: profile,
            })
          )
        })
      }
    }
  }, [user, dispatch])

  useEffect(() => {
    dispatch({ type: CURRENT_ACTION, payload: undefined })
  }, [dispatch])

  const register = user && !user.profile?.roles.includes('client')

  return (
    <SectionContainer alwaysShow={!user}>
      {user && !register ? (
        <ClientProjects
          selectedProject={selectedProject}
          setSelectedProject={setSelectedProject}
        />
      ) : (
        <Login role="client" register={false} />
      )}
    </SectionContainer>
  )
}

export default Client
