import React from 'react'

const DescriptiveSection = (props) => {
  const { content, title, key } = props

  return (
    <>
      <div
        key={key}
        style={{
          fontWeight: 'bolder',
          color: 'white',
          maxWidth: 400
        }}
      >
        <div
          style={{
            fontSize: 'clamp(100%, 2rem + 2vw, 32px)',
            fontFamily: 'Dancing Script',
            textAlign: 'center',
          }}
        >
          {title}
        </div>
        <div
          style={{
            fontSize: 'clamp(100%, 1rem + 2vw, 16px)',
            textAlign: 'center',
          }}
        >
          {content}
        </div>
      </div>
    </>
  )
}

export default DescriptiveSection
