import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { setCurrentAction } from '../../model/navigation'
import SectionContainer from '../main/SectionContainer'
import { Col, Row } from 'antd'
import { useHistory } from 'react-router'
import GoHomeAction from '../../widgets/GoHomeAction'
import ProjectsCard from './ProjectsCard'
import Project from './Project'
import ProjectSearch from './ProjectSearch'
import useProjects, { STATES } from './hooks/useProjects'
import DefaultAction from '../../widgets/DefaultAction'
import ProjectInformation from './ProjectInformation'
import ProjectsViewSwitch from './ProjectsViewSwitch'
import ProjectsListView from './ProjectsListView'
import { useProjectListener } from './hooks/useProjectListener'
import { addProject, removeProject } from '../../model/projects'

const Projects = (props) => {
  const { setCurrentAction } = props
  const history = useHistory()
  const [search, setSearch] = useState('')
  const [radioValue, setRadioValue] = useState('cards')
  const [landing, setLanding] = useState(true)
  const [clickFromTable, setClickFromTable] = useState(false)
  const [title, setTitle] = useState('Projects')
  const [selectedProject, setSelectedProject] = useState(undefined)
  const projects = useProjects(STATES.ANY)

  const projectCreated = (event) => {
    console.log('Project Created (in projects):', event)
    props.addProject({
      name: event.repository.name,
      repository_name: event.repository.name,
    })
  }

  const projectDeleted = (event) => {
    console.log('Project Deleted (in projects):', event)
    props.removeProject(event.repository.name)
  }

  useProjectListener({
    projectCreated: projectCreated,
    projectDeleted: projectDeleted,
  })

  const filteredProjects = projects.filter(
    (p) =>
      search === '' ||
      p.name.toLowerCase().includes(search.toLowerCase()) ||
      p.description.toLowerCase().includes(search.toLowerCase())
  )

  filteredProjects.sort((a, b) =>
    a.name.toLowerCase().localeCompare(b.name.toLowerCase())
  )

  var directed = false
  if (history.location.state && history.location.state.directed) {
    directed = true
  }

  if (history.location.search !== '' && landing) {
    setSearch(decodeURI(history.location.search.replace('?', '')))
    setLanding(false)
  }

  useEffect(() => {
    setCurrentAction(
      directed
        ? {
            ...GoHomeAction,
            action: (history) => {
              history.goBack()
            },
          }
        : GoHomeAction
    )
  }, [directed, setCurrentAction])

  const handleProjectSelected = (project, tableClick) => {
    if (tableClick) {
      setClickFromTable(true)
    }
    setTitle(project.name)
    setCurrentAction({
      ...DefaultAction,
      action: () => {
        setSelectedProject(undefined)
        setTitle('Projects')
        setCurrentAction(directed ? DefaultAction : GoHomeAction)
        setClickFromTable(false)
      },
    })
    setSelectedProject(project)
  }

  const projectCardInfo = [
    {
      title: 'Pending Projects',
      description:
        'These have a target pledge set, but are awaiting fullfillment',
      image: '/pending.png',
      target: '/projects/pending',
    },
    {
      title: 'Active Projects',
      description: 'Projects which are actively being developed',
      image: '/active.png',
      target: '/projects/active',
    },
    {
      title: 'Incubation Projects',
      description:
        'These are project ideas that have not yet set a target pledge',
      image: '/incubation.png',
      target: '/projects/incubation',
    },
  ]

  return (
    <SectionContainer title={title}>
      {selectedProject?.name ? null : (
        <Row align="middle">
          <ProjectSearch search={search} setSearch={setSearch} />
          <ProjectsViewSwitch
            radioValue={radioValue}
            setRadioValue={setRadioValue}
          />
        </Row>
      )}
      <Row
        type="flex"
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          padding: 20,
          paddingBottom: 50,
        }}
        align="middle"
      >
        {search !== '' || clickFromTable === true ? (
          selectedProject?.name ? (
            <ProjectInformation project={selectedProject} />
          ) : (
            <>
              <Row
                type="flex"
                style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexWrap: 'wrap',
                }}
                gutter={(16, 16)}
                align="middle"
              >
                {filteredProjects.map((p, index) => (
                  <Project
                    key={index}
                    title={p.name}
                    description={p.description}
                    onClick={() => handleProjectSelected(p)}
                  />
                ))}
              </Row>
            </>
          )
        ) : (
          <>
            {radioValue === 'cards' ? (
              <Col span={24} style={{ paddingTop: 10 }}>
                <Row type="flex" justify="space-between">
                  {projectCardInfo.map(
                    ({ title, description, image, target }) => (
                      <ProjectsCard
                        title={title}
                        description={description}
                        image={image}
                        target={target}
                      />
                    )
                  )}
                </Row>
              </Col>
            ) : (
              <Col span={22} style={{ paddingTop: 10 }}>
                <ProjectsListView
                  handleProjectSelected={handleProjectSelected}
                  filteredProjects={filteredProjects}
                />
              </Col>
            )}
          </>
        )}
      </Row>
    </SectionContainer>
  )
}

const mapDispathToProps = {
  setCurrentAction,
  addProject,
  removeProject,
}

export default connect(null, mapDispathToProps)(Projects)
