import { Button } from 'antd'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createIssue } from '../../external/GitIssues'
import { UPDATE_PROJECT } from '../../model/projects'
import { IIssue } from '../../model/types/IIssue'
import { IModelRoot } from '../../model/types/IModelRoot'
import IProject from '../../model/types/IProject'
import ProjectInformation from '../projects/ProjectInformation'
import ClientIssues from './ClientIssues'
import CreateIssue from './CreateIssue'

export interface IClientProjectProps {
  project: IProject
}

const ClientProject = (props: IClientProjectProps) => {
  const { project } = props
  const [creatingIssue, setCreatingIssue] = useState(false)
  const dispatch = useDispatch()

  const createNewIssue = (name: string, description: string) => {
    createIssue(
      'FMAY-Software',
      project.repository_name,
      name,
      description
    ).then((issue) => {
      project.issues.unshift(issue)
      dispatch({ type: UPDATE_PROJECT, payload: project })
    })
  }

  const toggleIssueAfterResolve = (incIssue: any) => {
    const { state, id } = incIssue
    switch (state) {
      case 'open':
        project.issues = [...project.issues, incIssue]
        project.closedIssues = project.closedIssues.filter(
          (row) => row.id !== id
        )
        break
      case 'closed':
        project.closedIssues = [...project.closedIssues, incIssue]
        project.issues = project.issues.filter((row) => row.id !== id)
        break
      default:
        break
    }
    dispatch({ type: UPDATE_PROJECT, payload: project })
  }

  const togglePrAfterResolve = (incIssue: any) => {
    const { state, id } = incIssue
    switch (state) {
      case 'open':
        project.pullRequests = [...project.pullRequests, incIssue]
        project.closedPullRequests = project.closedPullRequests.filter(
          (row) => row.id !== id
        )
        break
      case 'closed':
        project.closedPullRequests = [...project.closedPullRequests, incIssue]
        project.pullRequests = project.pullRequests.filter(
          (row) => row.id !== id
        )
        break
      default:
        break
    }
    dispatch({ type: UPDATE_PROJECT, payload: project })
  }

  const currentProject = useSelector((state: IModelRoot) =>
    state.projects.data?.find(
      (p) => p.repository_name === project.repository_name
    )
  )

  const issueMap = new Map<string, IIssue[]>()
  const keys = ['issues', 'closedIssues', 'pullRequests', 'closedPullRequests']
  keys.forEach((key) => {
    const keyIssues: IIssue[] = currentProject
      ? (currentProject[key as keyof IProject] as IIssue[])
      : []
    issueMap.set(key, keyIssues)
  })

  return currentProject ? (
    <>
      <div style={{ padding: 10, width: '80%', textAlign: 'start' }}>
        <ProjectInformation justifyContent={'stretch'} project={project} />
      </div>
      <div style={{ marginTop: 20, padding: 10 }}>
        {creatingIssue ? null : (
          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
            }}
          >
            <Button onClick={() => setCreatingIssue(true)}>
              New Conversation
            </Button>
          </div>
        )}
        <CreateIssue
          show={creatingIssue}
          createIssue={createNewIssue}
          setCreatingIssue={setCreatingIssue}
        />
        <ClientIssues
          project={currentProject}
          issues={issueMap}
          toggleIssueAfterResolve={toggleIssueAfterResolve}
          togglePrAfterResolve={togglePrAfterResolve}
        />
      </div>
    </>
  ) : null
}

export default ClientProject
