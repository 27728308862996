import React, { useState } from 'react'
import { Form, Input, Button } from 'antd'
import {
  getProfile,
  setUserProfile,
  signInEmail,
} from '../../external/FirebaseAPI'
import { USER } from '../../model/user'
import { useDispatch } from 'react-redux'
import { getGHKey } from '../../external/S3API'
import { GITHUB_KEY } from '../../model/github'
import { useSelector } from 'react-redux'
import { auth } from 'firebase'
import { ValidateErrorEntity } from 'rc-field-form/lib/interface'
import { IProfile, IUser } from '../../model/types/auth/IUser'
import { IModelRoot } from '../../model/types/IModelRoot'

export interface LoginProps {
  register: boolean
  role: string
}

const Login = (props: LoginProps) => {
  const dispatch = useDispatch()
  const [registering, setRegistering] = useState(
    props.register ? props.register : false
  )
  const user: IUser = useSelector((state: IModelRoot) => state.user)
  const [form] = Form.useForm()
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  }

  interface LoginInfo {
    email: string
    password: string
  }
  const onFinish = async (values: LoginInfo) => {
    // TODO: need to handle errors
    signInEmail(values.email, values.password)
      .then((res: auth.UserCredential) => {
        if (res.user !== null) {
          const user = res.user
          getProfile(res.user).then((profile) => {
            const additions: Partial<IProfile> = {
              roles: [props.role],
              lastLogin: Date.now(),
            }
            dispatch({
              type: USER,
              payload: {
                displayName: user.displayName ? user.displayName : 'Not Set',
                email: user.email ? user.email : 'Not Set',
                uid: user.uid,
                profile: { ...profile, ...additions },
              } as IUser,
            })
            // create a profile in database
            setUserProfile(res.user?.uid, { ...profile, ...additions })
          })
          // grab key to  enable github actions
          getGHKey().then((key) => {
            dispatch({ type: GITHUB_KEY, payload: key })
          })
        }
      })
      .catch((e) => {
        switch (e.code) {
          case 'auth/wrong-password':
            form.setFields([
              {
                name: 'password',
                errors: [e.message],
              },
            ])
            break
          default:
            form.setFields([
              {
                name: 'email',
                errors: [e.message],
              },
            ])
        }
      })
  }

  const onFinishFailed = (errorInfo: ValidateErrorEntity<LoginInfo>) => {
    console.log('Failed:', errorInfo)
  }

  const validateMessages = {
    types: {
      email: 'Please enter a valid email.',
    },
  }

  const validatePassword = (
    rule: unknown,
    value: string,
    callback: (message: string) => void
  ) => {
    if (value && value !== form.getFieldValue('password')) {
      callback('Passwords do not match!')
    } else {
      validatePasswordStrength(rule, value, callback)
    }
  }

  const validatePasswordStrength = (
    rule: unknown,
    value: string,
    callback: (message: string) => void
  ) => {
    if (value) {
      if (value.length < 6) {
        callback('Password must contain at least 6 values.')
      }
      if (
        !/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/.test(value)
      ) {
        callback(
          'Password must contain at least one special character and one digit.'
        )
      }
    }
  }

  const RegisterForm = () => {
    return (
      <>
        <Form.Item
          label="Company"
          name="company"
          rules={[
            { required: true, message: 'Please share your company name!' },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="First Name"
          name="firstname"
          rules={[
            { required: true, message: 'We need to know what to call you!' },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Last Name" name="lastname">
          <Input />
        </Form.Item>
      </>
    )
  }
  return (
    <div style={{ padding: 20 }}>
      <Form
        form={form}
        {...layout}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        validateMessages={validateMessages}
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: 'Please enter your registered client email.',
            },
            { type: 'email' },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: 'Please enter your password.' }]}
        >
          <Input.Password />
        </Form.Item>

        {registering && !user ? (
          <>
            <Form.Item
              label="Verify"
              name="verify"
              rules={[
                { required: true, message: 'Please re-enter your password.' },
                { validator: validatePassword },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <RegisterForm />
          </>
        ) : undefined}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          {registering ? (
            <Form.Item>
              <Button
                type="default"
                htmlType="button"
                onClick={() => setRegistering(false)}
              >
                Cancel
              </Button>
            </Form.Item>
          ) : undefined}
          <Form.Item style={{ marginLeft: 5 }}>
            <Button
              type="default"
              htmlType="button"
              onClick={() => setRegistering(true)}
            >
              Register
            </Button>
          </Form.Item>
          <Form.Item style={{ marginLeft: 5 }}>
            <Button
              type="primary"
              htmlType="button"
              onClick={() => form.submit()}
            >
              {user ? 'OK' : 'Submit'}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  )
}

export default Login
