import {
  IssuesOpenedEvent,
  ProjectCreatedEvent,
  ProjectDeletedEvent,
  RepositoryCreatedEvent,
  RepositoryDeletedEvent,
  Schema,
} from '@octokit/webhooks-types'

interface HandleBranch {
  do: <T>(handler: <T extends Schema>(event: T) => void) => CheckBranch
}

interface CheckBranch {
  onProjectCreated: () => HandleBranch
  onProjectDeleted: () => HandleBranch
  onRepositoryCreated: () => HandleBranch
  onRepositoryDeleted: () => HandleBranch
  onIssuesOpened: () => HandleBranch
}

export const usingEvent = <T extends Schema>(event: T): CheckBranch => {
  return {
    onProjectCreated: () => {
      return {
        do: (handler: <T extends Schema>(event: T) => void) => {
          if (
            (event as ProjectCreatedEvent).action === 'created' &&
            (event as ProjectCreatedEvent).project !== undefined
          ) {
            handler(event as ProjectCreatedEvent)
          }
          return usingEvent(event)
        },
      }
    },
    onProjectDeleted: () => {
      return {
        do: (handler: <T extends Schema>(event: T) => void) => {
          if (
            (event as ProjectDeletedEvent).action === 'deleted' &&
            (event as ProjectDeletedEvent).project !== undefined
          ) {
            handler(event as ProjectDeletedEvent)
          }
          return usingEvent(event)
        },
      }
    },
    onIssuesOpened: () => {
      return {
        do: (handler: <T extends Schema>(event: T) => void) => {
          if (
            (event as IssuesOpenedEvent).action === 'opened' &&
            (event as IssuesOpenedEvent).issue !== undefined
          ) {
            handler(event as IssuesOpenedEvent)
          }
          return usingEvent(event)
        },
      }
    },
    onRepositoryCreated: () => {
      return {
        do: (handler: <T extends Schema>(event: T) => void) => {
          if (
            (event as RepositoryCreatedEvent).action === 'created' &&
            (event as RepositoryCreatedEvent).repository !== undefined
          ) {
            handler(event as RepositoryCreatedEvent)
          }
          return usingEvent(event)
        },
      }
    },
    onRepositoryDeleted: () => {
      return {
        do: (handler: <T extends Schema>(event: T) => void) => {
          if (
            (event as RepositoryDeletedEvent).action === 'deleted' &&
            (event as RepositoryDeletedEvent).repository !== undefined
          ) {
            handler(event as RepositoryDeletedEvent)
          }
          return usingEvent(event)
        },
      }
    },
  }
}
