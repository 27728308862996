import { Button } from 'antd'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { IModelRoot } from '../../../model/types/IModelRoot'
import IProject from '../../../model/types/IProject'
import SignInButton from '../../../widgets/SignInButton'
import SectionContainer from '../../main/SectionContainer'
import ProjectForm from './ProjectForm'

export interface RequestsProps {}

const Requests = (props: RequestsProps) => {
  const [projectCreated, setProjectCreated] = useState<IProject | undefined>(
    undefined
  )
  const history = useHistory()
  const user = useSelector((state: IModelRoot) => state.user)
  const github = useSelector((state: IModelRoot) => state.github)
  const disabled = !user?.uid || !github.key

  var disabledMessage = 'Please sign in to create a project request.'
  if (user?.uid && !github.key) {
    disabledMessage =
      'You must have a GitHub FMAY key to create a project request.'
  }

  const navigateToProject = () => {
    history.push(`/projects?${projectCreated?.name}`)
  }

  return (
    <SectionContainer title="Project Request">
      {disabled ? (
        <>
          <p>{disabledMessage}</p>
          {!user?.uid ? <SignInButton history={history} size="small" /> : null}
        </>
      ) : projectCreated ? (
        <>
          <p>Project created successfully!</p>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Button onClick={() => navigateToProject()}>Go to project</Button>
            <Button
              style={{ marginLeft: 10 }}
              onClick={() => setProjectCreated(undefined)}
            >
              Create another?
            </Button>
          </div>
        </>
      ) : (
        <>
          <p style={{ margin: 0 }}>
            Create a project below to track a new product, feature, or bug fix.
          </p>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <ProjectForm setProjectCreated={setProjectCreated} />
          </div>
        </>
      )}
    </SectionContainer>
  )
}

export default Requests
