import { Button, Form, Input, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  createUserAccount,
  getRegisteredUsers,
  resetUserPassword,
} from '../../../external/FirebaseAPI'

const Clients = () => {
  const [users, setUsers] = useState([])
  const [selectedClient, setSelectedClient] = useState(undefined)
  const [enableClientCreation, setEnableClientCreation] = useState(false)
  const [form] = Form.useForm()

  const user = useSelector((state) => state.user)

  useEffect(() => {
    getRegisteredUsers(user).then((data) => {
      setUsers(Object.values(data).filter((u) => u.email !== undefined))
    })
  }, [user])

  const columns = [
    {
      title: 'User',
      dataIndex: 'email',
      key: 'email',
      width: '10%',
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: 'Roles',
      dataIndex: 'roles',
      key: 'roles',
      width: '20%',
    },
  ]

  const handleAccountSelection = (record) => {
    setSelectedClient(record.email)
  }

  const ButtonVerticallySpaced = (props) => {
    const { style, ...otherProps } = props
    return (
      <Button
        {...otherProps}
        style={{ ...style, marginBottom: 10, width: 150 }}
      >
        {props.children}
      </Button>
    )
  }

  const handleUserCreation = (values) => {
    createUserAccount(values)
      .then(() => {
        setEnableClientCreation(false)
      })
      .catch((e) => {
        switch (e.code) {
          case 'auth/weak-password':
            form.setFields([
              {
                name: 'password',
                errors: [e.message],
              },
            ])
            break
          default:
            form.setFields([
              {
                name: 'email',
                errors: [e.message],
              },
            ])
        }
      })
  }

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  }

  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  }

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        alignItems: 'flex-start',
        justifyContent: 'space-around',
        flexDirection: 'row',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: 350,
        }}
      >
        {selectedClient && <p style={{ color: 'red' }}>{selectedClient}</p>}
        <ButtonVerticallySpaced onClick={() => setEnableClientCreation(true)}>
          Create Account
        </ButtonVerticallySpaced>
        {enableClientCreation ? (
          <Form form={form} {...layout} onFinish={handleUserCreation}>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  type: 'email',
                },
                {
                  required: true,
                  message: 'You must enter a valid user email.',
                },
              ]}
            >
              <Input placeholder="Client email" />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: 'You must enter an initial user password.',
                },
              ]}
            >
              <Input.Password placeholder="Initial password" />
            </Form.Item>
            <Form.Item {...tailLayout}>
              <Button
                style={{ marginRight: 10 }}
                onClick={() => setEnableClientCreation(false)}
              >
                Cancel
              </Button>
              <Button htmlType="submit">Create</Button>
            </Form.Item>
          </Form>
        ) : null}
        <ButtonVerticallySpaced
          onClick={() => resetUserPassword(selectedClient)}
          disabled={!selectedClient}
        >
          Reset Password
        </ButtonVerticallySpaced>
        <ButtonVerticallySpaced disabled={!selectedClient}>
          Associate Project
        </ButtonVerticallySpaced>
        <ButtonVerticallySpaced danger disabled={!selectedClient}>
          Delete Account
        </ButtonVerticallySpaced>
      </div>
      <Table
        dataSource={users}
        columns={columns}
        bordered
        pagination={{ pageSize: 7, hideOnSinglePage: true }}
        size="small"
        style={{
          cursor: 'pointer',
          flex: 1,
          marginLeft: 125,
          marginRight: 20,
        }}
        rowKey={'email'}
        onRow={(record) => {
          return {
            onClick: () => handleAccountSelection(record),
          }
        }}
      />
    </div>
  )
}

export default Clients
