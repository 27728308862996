import { Select, Table } from 'antd'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateProjectConfiguration } from '../../external/GitProjects'
import useProjects, { STATES } from '../projects/hooks/useProjects'
import { USER } from '../../model/user'
import { Button } from 'antd'
import { setUserProfile } from '../../external/FirebaseAPI'

const AssignedProjects = (props) => {
  const assignedProjects = useProjects(STATES.ASSIGNED)
  const user = useSelector((state) => state.user)
  const { email, profile } = user
  const dispatch = useDispatch()

  if (email === undefined) {
    return null
  }

  const contributorProjects = assignedProjects.filter(
    (p) => p.owner.email === email
  )

  const assignedProjectColumns = [
    {
      title: 'Project',
      dataIndex: 'name',
      key: 'name',
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
        multiple: 1,
      },
      render: (text) => (
        <a href={'/projects/active?' + encodeURI(text)}>{text}</a>
      ),
    },
    {
      title: 'Pledged',
      dataIndex: 'pledged',
      key: 'pledged',
      sorter: {
        compare: (a, b) => parseInt(a.pledged) - parseInt(b.pledged),
        multiple: 3,
      },
      render: (text) => '$' + text,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: {
        compare: (a, b) => a.status.localeCompare(b.status),
        multiple: 2,
      },
      width: 150,
      render: (text, project) => {
        return (
          <Select
            value={project.status ? project.status : 'Incomplete'}
            onChange={(value) => setStatus(value, project)}
          >
            <Select.Option value={'Complete'} key={0}>
              Complete
            </Select.Option>
            <Select.Option value={'Incomplete'} key={1}>
              Incomplete
            </Select.Option>
          </Select>
        )
      },
    },
    {
      title: '',
      dataIndex: 'home',
      key: 'home',
      render: (text) => <a href={text}>Project Repository</a>,
    },
  ]

  const setStatus = (value, project) => {
    project.status = value
    updateProjectConfiguration('FMAY-Software', project)
  }

  const enableContributions = () => {
    let newProfile = {}
    if (profile) {
      const existingRoles = profile.roles ? profile.roles : []
      newProfile = {
        ...profile,
        roles: [...existingRoles, 'contributor'],
      }
    } else {
      newProfile = {
        roles: ['contributor'],
      }
    }
    dispatch({ type: USER, payload: { ...user, profile: newProfile } })
    // // grab key to  enable github actions
    // getGHKey().then((key) => {
    //   dispatch({ type: GITHUB_KEY, payload: key })
    // })
    // create a profile in database
    setUserProfile(user.uid, newProfile)
  }

  return (
    <div>
      {!profile?.roles?.includes('contributor') ? (
        <>
          <p>
            Are you interested in Contributing or taken ownership of a project?
          </p>
          <Button onClick={() => enableContributions()}>Yes?</Button>
        </>
      ) : contributorProjects.length === 0 ? (
        <p>
          Head over to the{' '}
          <a href={'/projects/incubation'}>projects in incubation</a>, or{' '}
          <a href={'/requests'}>create your own!</a>
        </p>
      ) : (
        <>
          <p>Assigned Projects</p>
          <Table
            dataSource={contributorProjects}
            rowKey={'name'}
            columns={assignedProjectColumns}
            style={{
              minWidth: 320,
              borderColor: 'dodgerblue',
              borderWidth: 1,
              borderStyle: 'solid',
            }}
            size="small"
          />
        </>
      )}
    </div>
  )
}

export default AssignedProjects
