import { Button } from 'antd'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import IProject from '../../model/types/IProject'
import useProjects, { STATES } from '../projects/hooks/useProjects'
import ClientMenu from './ClientMenu'
import ClientProject from './ClientProject'
import ClientProjectContributors from './ClientProjectContributors'
import { Typography } from 'antd'
const { Title, Text } = Typography
export interface ClientProjectsProps {
  selectedProject: IProject | undefined
  setSelectedProject: (project: IProject | undefined) => void
}

const ClientProjects = (props: ClientProjectsProps) => {
  const { selectedProject, setSelectedProject } = props
  const history = useHistory()
  const projects: IProject[] = useProjects(STATES.CLIENT)
  const [isScrolled, setIsScrolled] = useState<boolean>(false)
  window.onscroll = () => {
    const distanceY = document.documentElement.scrollTop
    distanceY > 45 ? setIsScrolled(true) : setIsScrolled(false)
  }

  return (
    <div className="client-projects-page">
      <div className="client-menu-area">
        <ClientMenu
          selectedProject={selectedProject}
          setSelectedProject={setSelectedProject}
          projects={projects}
        />
        <div style={{ paddingBottom: 40 }}>
          <div className="client-menu-header">Contributors</div>
          <ClientProjectContributors
            project={selectedProject}
            projects={projects}
          />
        </div>
      </div>
      {selectedProject ? (
        <div
          className="client-project"
          style={{ width: '100%', display: 'relative' }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              position: 'fixed',
              background: 'aliceblue',
              borderBottom: 'solid .5px dodgerblue',
              padding: 5,
              zIndex: 20,
              top: 59,
              left: 0,
              opacity: isScrolled && selectedProject?.name ? 1 : 0,
              transition: 'opacity .10s ease-in-out',
              width: '100%',
            }}
          >
            <Title level={5} style={{ margin: 0, marginRight: 10 }}>
              {selectedProject?.name}
            </Title>
            <Text style={{ display: 'flex', alignItems: 'end' }}>
              Project Status: {selectedProject?.status ?? 'Created'}
            </Text>
          </div>
          <ClientProject project={selectedProject} />
        </div>
      ) : (
        <div className="client-project">
          <span style={{ marginRight: 4 }}>
            Select a project from the menu or
          </span>
          <Button
            size="small"
            onClick={() => {
              history.push('/requests')
              return true
            }}
          >
            Request a new project
          </Button>
        </div>
      )}
    </div>
  )
}

export default ClientProjects
