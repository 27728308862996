export const ADD_PROJECT = '_projects'
export const REMOVE_PROJECT = '_projects_remove'
export const UPDATE_PROJECT = '_projects_update'
export const CLEAR_PROJECTS = '_projects_clear'
export const PROJECTS_LOADING = '_projects_loading'
export const LAST_PROJECT_LOAD = '_projects_last_project_load'
export const SET_REPOSITORIES = '_repositories'
export const REPOSITORIES_LOADING = '_repositories_loading'

/** Reducers */
export const projects = (
  state = { data: [], loading: false, lastLoad: 0 },
  action
) => {
  switch (action.type) {
    case ADD_PROJECT:
      return {
        ...state,
        data: [...state.data, action.payload],
      }
    case REMOVE_PROJECT:
      return {
        ...state,
        data: state.data.filter((p) => p.name !== action.payload),
      }
    case UPDATE_PROJECT:
      const project = action.payload
      const otherProjects = state.data.filter((p) => p.name !== project.name)
      const updatedProjects = [...otherProjects, project]
      return {
        ...state,
        data: updatedProjects,
      }
    case PROJECTS_LOADING:
      return { ...state, loading: action.payload }
    case LAST_PROJECT_LOAD:
      return { ...state, lastLoad: action.payload }
    case CLEAR_PROJECTS:
      return { data: [], loading: false, lastLoad: 0 }
    default:
      return state
  }
}

export const repositories = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case SET_REPOSITORIES:
      return { ...state, data: action.payload }
    case REPOSITORIES_LOADING:
      return { ...state, loading: action.payload }
    default:
      return state
  }
}

/** Actions */
export const addProject = (project) => {
  return {
    type: ADD_PROJECT,
    payload: project,
  }
}

export const removeProject = (name) => {
  return {
    type: REMOVE_PROJECT,
    payload: name,
  }
}

export const updateProject = (project) => ({
  type: UPDATE_PROJECT,
  payload: project,
})

export const setLastLoad = (lastLoad) => ({
  type: LAST_PROJECT_LOAD,
  payload: lastLoad,
})

export const clearProjects = () => {
  return {
    type: CLEAR_PROJECTS,
    payload: [],
  }
}

export const setProjectsLoading = (val) => {
  return {
    type: PROJECTS_LOADING,
    payload: val,
  }
}

export const setRepositories = (repos) => {
  return {
    type: SET_REPOSITORIES,
    payload: repos,
  }
}

export const setRepositoriesLoading = (val) => {
  return {
    type: REPOSITORIES_LOADING,
    payload: val,
  }
}
