import React, { useEffect, useState } from 'react'
import { Button, Form, Input, Switch } from 'antd'
import { setUserProfile } from '../../../external/FirebaseAPI'
import { useDispatch, useSelector } from 'react-redux'
import { USER } from '../../../model/user'

const Profile = (props) => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)
  const [valuesChanged, setValuesChanged] = useState(false)
  const [ready, setReady] = useState(false)

  const handleProfileChange = (values) => {
    // cannot send undefined to database
    values = {
      ...values,
      anonymous: values.anonymous ? values.anonymous : false,
    }
    user.profile = values
    dispatch({ type: USER, payload: user })
    setUserProfile(user.uid, { ...user.profile, ...values })
    setValuesChanged(false)
  }

  useEffect(() => {
    if (user.profile && Object.keys(user.profile).length > 0) {
      setReady(true)
    }
  }, [user])

  if (!ready) {
    return null
  }

  return (
    <Form
      labelCol={{ span: 10 }}
      wrapperCol={{ span: 14 }}
      layout="horizontal"
      onValuesChange={() => setValuesChanged(true)}
      onFinish={handleProfileChange}
      size={'small'}
    >
      <Form.Item
        style={{ marginBottom: 5 }}
        initialValue={user.profile.seenAs}
        name="seenAs"
        label="Seen As"
      >
        <Input />
      </Form.Item>
      <Form.Item
        style={{ marginBottom: 5 }}
        initialValue={user.profile.company}
        name="company"
        label="Company"
      >
        <Input />
      </Form.Item>
      <Form.Item
        style={{ marginBottom: 5 }}
        initialValue={user.profile.url}
        name="url"
        label="Website/Email"
      >
        <Input />
      </Form.Item>
      <Form.Item style={{ marginBottom: 5 }} name="anonymous" label="Anonymous">
        <Switch defaultChecked={user.profile.anonymous} />
      </Form.Item>
      <Button disabled={!valuesChanged} htmlType="submit">
        Save
      </Button>
    </Form>
  )
}

export default Profile
