import React from 'react'

const CompanyInfo = (props) => {
  const company_number = '463-204-9109'
  return (
    <div
      style={{
        borderTopColor: 'dodgerblue',
        borderTopWidth: 1,
        borderTopStyle: 'solid',
        borderBottomColor: 'dodgerblue',
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        backgroundColor: 'aliceblue',
        minWidth: '100%',
        paddingBottom: 20,
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 20,
          minHeight: 100,
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: '50%',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <span style={{ marginRight: 10 }}>Contact us:</span>
            <a href="mailto:travis.london@fmay.net">travis.london@fmay.net</a>
            <span style={{ marginRight: 10 }}>Phone:</span>
            <a href={`tel:${company_number}`}>{company_number}</a>
          </div>
        </div>
        <div
          style={{
            fontFamily: 'Dancing Script',
            fontWeight: 1000,
            fontSize: 18,
          }}
        >
          © FMAY Software
        </div>
      </div>
    </div>
  )
}

export default CompanyInfo
