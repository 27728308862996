import { getToken, USER_AGENT } from './GitAPI'
import { Octokit } from '@octokit/rest'
import { getOctokits } from './GitAPI'
import { createIssue } from './GitIssues'

const push = async (organization, repository, base, head, changes) => {
  const { appKit } = await getOctokits(undefined)
  var response

  if (!base) {
    response = await appKit.repos.get({
      owner: organization,
      repo: repository,
    })
    // tslint:disable-next-line:no-parameter-reassignment
    base = response.data.default_branch
  }

  response = await appKit.repos.listCommits({
    owner: organization,
    repo: repository,
    sha: base,
    per_page: 1,
  })
  var latestCommitSha = response.data[0].sha
  const treeSha = response.data[0].commit.tree.sha

  response = await appKit.git.createTree({
    owner: organization,
    repo: repository,
    base_tree: treeSha,
    tree: Object.keys(changes.files).map((path) => {
      const mode = '100644'
      return {
        path,
        mode,
        content: changes.files[path],
      }
    }),
  })
  const newTreeSha = response.data.sha

  response = await appKit.git.createCommit({
    owner: organization,
    repo: repository,
    message: changes.commit,
    tree: newTreeSha,
    parents: [latestCommitSha],
  })
  latestCommitSha = response.data.sha

  return await appKit.git.updateRef({
    owner: organization,
    repo: repository,
    sha: latestCommitSha,
    ref: `heads/${head}`,
    force: true,
  })
}

export const commitChangeSet = async (
  organization,
  repository,
  base,
  head,
  files,
  commitMessage
) => {
  const changes = {
    files: files,
    commit: commitMessage,
  }
  return await push(organization, repository, base, head, changes)
}

export const createRepository = async (
  organization,
  user,
  name,
  description,
  project,
  about,
  isPrivate
) => {
  const token = await getToken()

  const octokit = new Octokit({
    auth: token,
    userAgent: USER_AGENT,
  })

  var response = await octokit.repos.createInOrg({
    org: organization,
    name: name,
    description: JSON.stringify(about),
    private: isPrivate,
    has_issues: true,
    has_projects: true,
  })

  const status = response.status
  if (status === 201) {
    response = await createFile(
      octokit,
      organization,
      name,
      'Readme.md',
      createReadme(name, description, isPrivate),
      'Introduce repository readme.'
    )
    response = await createFile(
      octokit,
      organization,
      name,
      'configuration.json',
      JSON.stringify(project, undefined, 2),
      'Introduce configuration.'
    )
  }
  if (status === 201) {
    // private repositories cannot have a page site
    // without a cost, may add later
    if (!isPrivate) {
      response = await octokit.repos.createPagesSite({
        owner: organization,
        repo: name,
        source: {
          branch: 'main',
          path: '/docs',
        },
      })
      const pageSiteStatus = response.status
      if (pageSiteStatus === 201) {
        // add the page contents
        await createFile(
          octokit,
          organization,
          name,
          'docs/Readme.md',
          createPagesReadme(name, description),
          'Introduce pages content.',
          user
        )
        await createFile(
          octokit,
          organization,
          name,
          'docs/_config.yml',
          'theme: jekyll-theme-modernist',
          'Introduce pages configuration.',
          user
        )
      }
    }
    // create the project issue
    await createIssue(
      organization,
      name,
      `${name} Project`,
      `Starting ${name} project initial conversation...`
    )
  }
}

const createFile = async (
  octokit,
  organization,
  repository,
  path,
  content,
  message
) => {
  return await octokit.repos.createOrUpdateFileContents({
    owner: organization,
    repo: repository,
    path: path,
    content: btoa(content),
    message: message,
  })
}
const createReadme = (name, description, isPrivate) => {
  return `# ${name}
${description}
${
  isPrivate
    ? ''
    : `

<a id="Project Information"></a>[Project Information](https://fmay-software.github.io/${name}/)
  `
}
  `
}

const createPagesReadme = (name, description) => {
  return `# ${name} - Project site

## Description
  
${description}
  
## Requirements
  
Enter the requirements including the acceptance tests matching the stated requirements.  This is a very important section as it allows interested parties something to base a pledge on.  
  
## Timeframe  
  
Enter the project effort estimate here, including an estimated delivery time.  Note that a project is not complete unless all requirements are reached and the pledge goal has been reached.  
  
## Pledging
  
Enter the pledge goal here.  Include information regarding the pledge amount.
  
### Pledge to this project
Send an e-mail to [FMAY](mailto:travis.london@gmail.com) to pledge.  
  
Pledge status:  
  
![progress](http://progressed.io/bar/0 "progress")

  `
}
