import React from 'react'
import { Card } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock } from '@fortawesome/free-solid-svg-icons'
const { Meta } = Card

const Project = (props) => {
  const { title, description, onClick, isPrivate } = props
  const adjustedDescription =
    description.length > 65 ? description.substring(0, 65) : description

  return (
    <div style={{ margin: 15 }}>
      <Card
        style={{
          minHeight: 125,
          maxHeight: 125,
          width: '20rem',
          borderColor: 'dodgerblue',
        }}
        hoverable={true}
        onClick={onClick}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: 10,
          }}
        >
          {isPrivate ? (
            <FontAwesomeIcon
              icon={faLock}
              color="silver"
              style={{ marginRight: 10 }}
            />
          ) : undefined}
          <Meta title={title} />
        </div>
        <Meta description={adjustedDescription} />
        {adjustedDescription.length === 65 ? (
          <span style={{ color: 'grey', fontSize: 25, lineHeight: 0.1 }}>
            ...
          </span>
        ) : null}
      </Card>
    </div>
  )
}
export default Project
