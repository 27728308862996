import React from 'react'
import SectionContainer from '../main/SectionContainer'
const PledgingModel = () => {
  return (
    <SectionContainer title="Pledging Model" hideAbout defaultAction>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <div style={{ maxWidth: 450 }}>
          <p>
            The pledging model works by interested parties pledging value in a
            given project. The pledge amount is transferred into an escrow
            account. The escrow funds shall not be received by FMAY until the
            point of software release to the community. The software project
            shall not be released, and thus interested parties will not be
            charged, until all project requirements are met.
          </p>

          <p>
            All projects shall be hosted in a GitHub repository. Each repository
            shall host the current pledge status as well as the requirements
            that the project shall meet. Project requirements shall be set forth
            by FMAY software, but shall be negotiable. Each project repository
            shall also have an issues database. Issues may be raised in the
            database for requirement negotiations and or project pledge amount
            negotiations.
          </p>

          <p>
            A reasonable attempt shall be made to estimate the effort in
            completing a project. Each project shall come with an effort
            estimate within its GitHub respository. While there shall be no
            guarantee of completion time, everything within reason shall be done
            to reach the target completion date.
          </p>

          <p>
            Prior to a project’s completion each pledging party shall have
            access to a demonstrable delivery. See{' '}
            <a href="/about/pledging/pledge_model">Pledging Cycle Diagram</a>{' '}
            for information regarding the pledging cycle.
          </p>

          <p>
            Once a project is complete all pledges shall be collected from
            escrow. The project will not be delivered until all collections have
            been made. If the project is not completed in an agreeable manner to
            any interested party, then all pledges shall be returned from
            escrow. At this point a new pledging phase shall occur with any
            negotiations required.
          </p>

          <p>
            Pledging status is monitored on the project website, but party
            information is kept confidential.
          </p>
          <p>
            Please{' '}
            <a href="mailto:travis.london@fmay.net?subject=Pledging Information Request">
              Email FMAY
            </a>{' '}
            for further information.
          </p>
        </div>
      </div>
    </SectionContainer>
  )
}

export default PledgingModel
