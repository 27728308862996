import React from 'react'
import { useSelector } from 'react-redux'
import AccessDenied from '../../../exceptions/AccessDenied'
import SectionContainer from '../../main/SectionContainer'
import Clients from './Clients'

const Accounts = props => {
  const github = useSelector(state => state.github)

  if (!github.membership?.isAdmin) {
    return <AccessDenied />
  }

  return (
    <SectionContainer title='FMAY Accounts'>
      <Clients />
    </SectionContainer>
  )
}

export default Accounts
