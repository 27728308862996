import { getSignedURL, uploadFile } from '../../external/S3API'

export const uploadImageCallBack = (file) => {
  uploadFile(`client_images/${file.name}`, file)
  return new Promise((resolve) => {
    resolve({
      data: { link: getSignedURL(`client_images/${file.name}`) }, //Temp link
    })
  })
}
