import { Button } from 'antd'
import React from 'react'

const SignInButton = props => {
  const { history, size } = props
  return (
    <Button onClick={() => history.push('/login')} size={size}>
      Sign In
    </Button>
  )
}

export default SignInButton
