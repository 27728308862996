import {
  ProjectCreatedEvent,
  ProjectDeletedEvent,
  Schema,
} from '@octokit/webhooks-types'
import { useCallback, useEffect } from 'react'
import { useFmayUpdates } from '../../../hooks/useFmayUpdates'
import { usingEvent } from '../../../model/types/fmayEvents/Webhooks'
import { FMAYUpdateListener } from '../../../model/types/FMAYUpdateListener'

export interface Handlers {
  projectCreated: (event: ProjectCreatedEvent) => void
  projectDeleted: (event: ProjectDeletedEvent) => void
}

export const useProjectListener = (handlers: Handlers): void => {
  const { registerListener, unregisterListener } = useFmayUpdates()

  const listener: FMAYUpdateListener = {
    id: 'projects',
    onMessage: (data: Schema) => {
      usingEvent(data)
        .onRepositoryCreated()
        .do(() => handlers.projectCreated(data as ProjectCreatedEvent))
        .onRepositoryDeleted()
        .do(() => handlers.projectDeleted(data as ProjectDeletedEvent))
    },
  }

  const localRegisterListener = useCallback(
    (listener: FMAYUpdateListener) => {
      registerListener(listener)
    },
    [registerListener]
  )

  const localUnregisterListener = useCallback(
    (listener: FMAYUpdateListener) => {
      unregisterListener(listener)
    },
    [unregisterListener]
  )

  useEffect(() => {
    localRegisterListener(listener)
    return () => {
      localUnregisterListener(listener)
    }
  }, [listener, localRegisterListener, localUnregisterListener])
}
