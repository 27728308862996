import React from 'react'
import { useMediaQuery } from 'react-responsive'

export interface LandingPageContentProperties {
  image: string
  imageAlt: string
  content: Array<string | HTMLParagraphElement>
}

const LandingPageContent = (props: LandingPageContentProperties) => {
  const { image, imageAlt, content } = props

  const isSmall = useMediaQuery({ maxWidth: 500 })

  return (
    <div className="fmay-software--landing-page-content">
      <div style={{ textAlign: 'center' }}>
        <img
          style={{
            width: isSmall ? 200 : 400,
            marginRight: isSmall ? 0 : 100,
            marginBottom: isSmall ? 20 : 0,
          }}
          src={image}
          alt={imageAlt}
        />
      </div>
      <div style={{ maxWidth: 450 }}>
        {content.map((p) =>
          typeof p === 'string' ? (
            <p className="fmay-software--landing-page-content-paragraph">{p}</p>
          ) : (
            <div className="fmay-software--landing-page-content-paragraph">
              {p}
            </div>
          )
        )}
      </div>
    </div>
  )
}

export default LandingPageContent
