export interface Application {
  name: string
  status: ApplicationStatus
  module: any
  component?: React.FC<unknown>
}
export enum ApplicationStatus {
  LOADING,
  LOADED,
  UNLOADED,
  ERROR,
}
