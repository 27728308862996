import React from 'react'
import { setGHKey } from '../model/github'
import { connect } from 'react-redux'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'antd'

const Footer = (props) => {
  const setGHKey = props.setGHKey
  const github = props.github
  const { isDirect } = props

  const readPEM = async (e) => {
    e.preventDefault()
    const reader = new FileReader()
    reader.onload = async (e) => {
      const pem = e.target.result
      setGHKey(pem)
    }
    reader.readAsText(e.target.files[0])
  }
  return (
    <div className="fixed-bottom">
      <footer
        style={{
          fontFamily: 'Dancing Script',
          fontWeight: 1000,
          fontSize: 22,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        © FMAY Software
        {!isDirect ? (
          <div
            style={{
              position: 'absolute',
              left: 'calc(100vw - 25px)',
              top: -6,
            }}
          >
            <Tooltip
              placement="bottom"
              title={() => {
                const status =
                  props.github && props.github.key && props.github.key !== ''
                    ? 'Connected'
                    : 'Disconnected'
                return 'GitHub: ' + status
              }}
            >
              <label
                style={{ width: 20, height: '100%', textAlign: 'center' }}
                htmlFor="file-upload"
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    height: 40,
                    color: github.key ? 'yellowgreen' : 'orange',
                  }}
                >
                  <FontAwesomeIcon size="xs" icon={faCircle} />
                </div>
              </label>
              {github.key ? null : (
                <input
                  style={{ display: 'none' }}
                  id="file-upload"
                  type="file"
                  onChange={(e) => {
                    readPEM(e)
                  }}
                />
              )}
            </Tooltip>
          </div>
        ) : null}
      </footer>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    github: state.github,
  }
}

const mapDispatchToProps = {
  setGHKey,
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer)
