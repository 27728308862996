import React from 'react'
import ProjectProgress from './ProjectProgess'
import ProjectContents from './ProjectContents'
import IProject from '../../model/types/IProject'

export interface IProjectInformationProps {
  project: IProject
  justifyContent?: string
}

const ProjectInformation = (props: IProjectInformationProps): JSX.Element => {
  const { project, justifyContent } = props
  return (
    <>
      <ProjectContents justifyContent={justifyContent} project={project} />
      <ProjectProgress project={project} />
    </>
  )
}

export default ProjectInformation
