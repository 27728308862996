import { Button, Input } from 'antd'
import React, { useEffect, useState } from 'react'
import { EditorState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import CustomEditor from '../../widgets/editor/CustomEditor'

const CreateIssue = (props) => {
  const { show, setCreatingIssue, createIssue } = props
  const [name, setName] = useState('')
  const [description, setDescription] = useState(EditorState.createEmpty())

  useEffect(() => {
    return () => {
      //Reset state on 'show'
      setName('')
      setDescription(EditorState.createEmpty())
    }
  }, [show])

  if (!show) {
    return null
  }

  const newIssue = () => {
    createIssue(
      name,
      draftToHtml(convertToRaw(description.getCurrentContent()))
    )
    setDescription('')
    setCreatingIssue(false)
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        justifyContent: 'space-around',
        width: '100%',
      }}
    >
      <div style={{ width: '100%', textAlign: 'left', marginBottom: 10 }}>
        Conversation Title
        <Input
          placeholder="Name your conversation here"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div style={{ width: '100%' }}>
        <CustomEditor
          editorState={description}
          onEditorStateChange={(editorState) => setDescription(editorState)}
          placeholder="Enter the content of the conversation here"
        />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            margin: '10px 0px 10px',
          }}
        >
          <Button
            style={{ marginRight: 10 }}
            onClick={() => setCreatingIssue(false)}
          >
            Cancel
          </Button>
          <Button onClick={() => newIssue()}>Create</Button>
        </div>
      </div>
    </div>
  )
}

export default CreateIssue
