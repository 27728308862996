import { Progress } from 'antd'
import React from 'react'

export interface IPledgedProjectProgressProps {
  pledged: string
  pledge_target: string
  complete: string
}

const PledgedProjectProgress = (
  props: IPledgedProjectProgressProps
): JSX.Element => {
  const { pledged, pledge_target, complete } = props
  const completeNumber = parseInt(complete)
  const isClient = window.location.hostname.startsWith('client.')
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        padding: 20,
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: 250,
        }}
      >
        <span>{isClient ? 'Budget' : 'Pledge Goal'}:</span>
        <span>
          ${pledged} / ${pledge_target}
        </span>
      </div>
      <Progress
        style={{ width: '75%' }}
        showInfo={completeNumber < 100}
        strokeColor={completeNumber > 100 ? 'gold' : undefined}
        percent={pledged === '0' ? 0 : completeNumber}
      />
    </div>
  )
}

export default PledgedProjectProgress
