import React, { useEffect } from 'react'
import { setCurrentAction } from '../../model/navigation'
import SectionContainer from './SectionContainer'
import { connect } from 'react-redux'
import FmayIntro from '../intro/FmayIntro'
import AboutFmay from '../intro/AboutFmay'
import Creation from '../intro/Creation'
import Projects from '../intro/Projects'
import Support from '../intro/Support'
import Contracting from '../intro/Contracting'
import CompanyInfo from './CompanyInfo'

const Landing = props => {
  const { setCurrentAction } = props
  useEffect(() => {
    setCurrentAction({})
  }, [setCurrentAction])
  return (
    <SectionContainer hideFooter>
      <FmayIntro />
      <AboutFmay />
      <Projects />
      <Creation />
      <Support />
      <Contracting />
      <CompanyInfo />
    </SectionContainer>
  )
}

const mapDispatchToProps = {
  setCurrentAction
}

export default connect(null, mapDispatchToProps)(Landing)
