import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Landing from '../sections/main/Landing'
import RouteUnknown from '../exceptions/RouteUnknown'
import AboutUs from '../sections/about/AboutUs'
import Login from '../sections/user/Login'
import Projects from '../sections/projects/Projects'
import { STATES } from '../sections/projects/hooks/useProjects'
import Pledge from '../sections/user/pledgor/Pledge'
import ContributorHome from '../sections/contributions/ContributorHome'
import Requests from '../sections/projects/requests/Requests'
import Accounts from '../sections/administration/accounts/Accounts'
import Client from '../sections/client/Client'
import ClientProject from '../sections/client/ClientProject'
import CategorizedProjects from '../sections/projects/CategorizedProjects'
import Contributor from '../sections/contributor/Contributor'
import BookKeeping from '../sections/bookkeeping/BookKeeping'
import PledgingModel from '../sections/about/PledgingModel'
import PledgeModel from '../sections/about/PledgeModel'

const routes = [
  {
    path: '/',
    component: Landing,
  },
  {
    path: '/projects',
    component: Projects,
  },
  {
    path: '/projects/active',
    component: () => (
      <CategorizedProjects
        projectState={STATES.ASSIGNED}
        mainTitle="Active Projects"
      />
    ),
  },
  {
    path: '/projects/pending',
    component: () => (
      <CategorizedProjects
        projectState={STATES.PENDING}
        mainTitle="Pending Projects"
      />
    ),
  },
  {
    path: '/projects/incubation',
    component: () => (
      <CategorizedProjects
        projectState={STATES.INCUBATION}
        mainTitle="Incubation Projects"
      />
    ),
  },
  {
    path: '/about',
    component: AboutUs,
  },
  {
    path: '/pledge',
    component: Pledge,
  },
  {
    path: '/requests',
    component: Requests,
  },
  {
    path: '/contributor',
    component: ContributorHome,
  },
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/admin/accounts',
    component: Accounts,
  },
  {
    path: '/client/project',
    component: ClientProject,
  },
  {
    path: '/about/pledging',
    component: PledgingModel,
  },
  {
    path: '/about/pledging/pledge_model',
    component: PledgeModel,
  },
  {
    path: null,
    component: RouteUnknown,
  },
]

const Root = () => {
  const isClient = window.location.hostname.startsWith('client.')
  const isContrib = window.location.hostname.startsWith('contributor.')
  return (
    <BrowserRouter>
      <Switch>
        <Route key={'books'} exact path={'/books'} component={BookKeeping} />
        {routes.map(({ path, component }, i) =>
          // if client page, use /client as /
          isClient && path === '/' ? (
            <Route key={i} exact path={'/'} component={Client} />
          ) : isContrib && path === '/' ? (
            <Route key={i} exact path={'/'} component={Contributor} />
          ) : (
            <Route key={i} exact path={path} component={component} />
          )
        )}
      </Switch>
    </BrowserRouter>
  )
}

export default Root
