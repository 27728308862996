import { Button, Input } from 'antd'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setPledges } from '../../../external/FirebaseAPI'
import { updateProjectConfiguration } from '../../../external/GitProjects'
import { UPDATE_PROJECT } from '../../../model/projects'
import { USER } from '../../../model/user'
import {
  addPledge,
  addPledgeToUser
} from '../../../model/services/pledging/pledges'

const Pledge = props => {
  const { setShowPledgeEntry } = props
  var { project } = props
  const dispatch = useDispatch()
  const [pledgeAmount, setPledgeAmount] = useState(100)
  const user = useSelector(state => state.user)

  const updatePledge = () => {
    project = addPledge(project, pledgeAmount, user)
    const newUser = addPledgeToUser(project, pledgeAmount, user)
    dispatch({ type: USER, payload: newUser })
    setPledges(user.uid, newUser.pledges)
    dispatch({ type: UPDATE_PROJECT, payload: project })
    updateProjectConfiguration('FMAY-Software', project)
    setPledgeAmount(100)
    setShowPledgeEntry(false)
  }

  const cancelPledge = () => {
    setPledgeAmount(100)
    setShowPledgeEntry(false)
  }

  return (
    <Input.Group style={{ width: 241 }}>
      <Input
        min={1}
        step={100}
        value={pledgeAmount}
        style={{ width: 125, textAlign: 'start', padding: 0 }}
        onChange={e => {
          setPledgeAmount(e.target.value)
        }}
      />
      <Button size='small' onClick={() => cancelPledge()}>
        Cancel
      </Button>
      <Button size='small' onClick={() => updatePledge()}>
        Pledge
      </Button>
    </Input.Group>
  )
}

export default Pledge
