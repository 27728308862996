import React from 'react'
import { Input } from 'antd'

const ProjectSearch = props => {
  const { search, setSearch } = props
  return (
    <div style={{ padding: 15 }}>
      <Input
        style={{ width: 250, textAlign: 'start' }}
        allowClear
        onChange={e => setSearch(e.target.value)}
        placeholder='Enter name or category...'
        value={search}
      />
    </div>
  )
}

export default ProjectSearch
