import React from 'react'
import LandingPage from './LandingPage'
import LandingPageContent from './LandingPageContent'

const Contracting = () => {
  return (
    <LandingPage anchor="contracting" next_anchor="" next_title="Back">
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <LandingPageContent
          image={'contract.png'}
          imageAlt={'FMAY: Contracting services'}
          content={[
            'FMAY is happy to help you implement your ideas within your own organization.',
            'Whether you want help building something close sourced or you would like to champion an open source project, FMAY can help.',
            'Have some elusive bugs in existing software, we can help.',
            'Each contract is unique, so pricing is on a case-by-case basis. See the Contracting page for further information.',
          ]}
        />
      </div>
    </LandingPage>
  )
}

export default Contracting
