import React from 'react'
import LandingPage from './LandingPage'
import LandingPageContent from './LandingPageContent'

const Support = () => {
  return (
    <LandingPage
      anchor="support"
      next_anchor="contracting"
      next_title="Contracting"
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <LandingPageContent
          image="support.png"
          imageAlt="FMAY: Support"
          content={[
            <p>
              Let’s face it software will always have issues regardless of how
              much testing was done before shipping.
            </p>,
            <p>
              Projects at FMAY are tested before delivery, but in the case
              problems are found or further enhancement is desired FMAY is here
              for support.
            </p>,
            <p>
              Support is provided through contractual agreements between FMAY
              and you.
            </p>,
          ]}
        />
      </div>
    </LandingPage>
  )
}

export default Support
