import { Table, List } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'

const Pledges = (props) => {
  const pledgeColumns = [
    {
      title: 'Project',
      dataIndex: 'project',
      key: 'project',
      sorter: {
        compare: (a, b) => a.project.localeCompare(b.project),
        multiple: 1,
      },
      render: (text) => <a href={'/projects?' + encodeURI(text)}>{text}</a>,
    },
    {
      title: 'Pledged',
      dataIndex: 'pledged',
      key: 'pledged',
      sorter: {
        compare: (a, b) => parseInt(a.pledged) - parseInt(b.pledged),
        multiple: 2,
      },
      width: 200,
      render: (text, data) => {
        return (
          <List style={{ textAlign: 'right' }} header={'Total: $' + text}>
            {data.pledges.map((p) => {
              const date = new Date(p.date)
              const month = date.getMonth()
              const year = date.getFullYear()
              return (
                <List.Item style={{ textAlign: 'right' }}>
                  {month}/{year} - ${p.amount}
                </List.Item>
              )
            })}
          </List>
        )
      },
    },
  ]

  const pledges = useSelector((state) => state.user.pledges)

  return (
    <div>
      <p>Pledges</p>
      <Table
        dataSource={pledges}
        columns={pledgeColumns}
        rowKey={'project'}
        style={{
          minWidth: 320,
          borderColor: 'dodgerblue',
          borderWidth: 1,
          borderStyle: 'solid',
        }}
        size="small"
      />
    </div>
  )
}

export default Pledges
