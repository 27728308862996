export const addPledge = (project, pledge, user) => {
  // update pledge total
  project.pledged = (parseInt(project.pledged) + parseInt(pledge)).toString()
  // update pledgor entry
  const pledgors = project.pledgors ? project.pledgors : []
  const pledgorEntry = pledgors.filter((p) => p.id === user.displayName)
  var pledgor = {}
  if (pledgorEntry.length === 1) {
    pledgor = pledgorEntry[0]
  } else {
    if (user.profile) {
      if (user.profile.anonymous) {
        pledgor.id = 'Anonymous'
      } else {
        pledgor.id = user.profile.seenAs ? user.profile.seenAs : 'Anonymous'
        pledgor.contact = user.profile.url
        pledgor.company = user.profile.company
      }
    }
    pledgor.pledged = '0'
  }
  const others = pledgors.filter((p) => p.id !== user.uuid)
  pledgor.pledged = (parseInt(pledgor.pledged) + parseInt(pledge)).toString()
  project.pledgors = [pledgor, ...others]
  return project
}

/**
 * "pledges": [{
 *   "project":"",
 *   "pledged":"",
 *   pledges: [ {"amount":"", "date":""} ]
 *  }]
 */
export const addPledgeToUser = (project, pledge, user) => {
  var pledges = user.pledges || []
  const otherPledges = pledges.filter((p) => p.project !== project.name)
  const pledgeList = pledges.filter((p) => p.project === project.name)
  var newPledge = { project: project.name, pledged: 0, pledges: [] }
  if (pledgeList.length === 1) {
    newPledge = pledgeList[0]
  }
  newPledge.pledged = (
    parseInt(newPledge.pledged) + parseInt(pledge)
  ).toString()
  newPledge.pledges = [
    {
      by: user.profile.seenAs ? user.profile.seenAs : 'Anonymous',
      amount: parseInt(pledge),
      date: Date.now(),
    },
    ...newPledge.pledges,
  ]
  user.pledges = [...otherPledges, newPledge]
  return user
}
