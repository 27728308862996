import { Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import SectionContainer from '../sections/main/SectionContainer'
import { ApplicationStatus } from '../model/types/ApplicationTypes'
import { useApplication } from './hooks/useApplication'
import './ExternalApplication.css'

export interface ExternalApplicationProps {
  applicationName: string
  host: string
  port?: number
  manifestPath: string
  appType: string
  branding: string
}
const ExternalApplication = (props: ExternalApplicationProps): JSX.Element => {
  const [error, setError] = useState<string>('')
  const { applicationName, host, port, appType, manifestPath, branding } = props
  const { mountApplication, application } = useApplication(
    host,
    manifestPath,
    applicationName,
    appType,
    branding,
    port
  )

  const container = 'fmay-external-container'

  useEffect(() => {
    if (application.status === ApplicationStatus.LOADED) {
      const result = mountApplication(application.module, container)
      if (!result) {
        setError(`${applicationName} application not available!`)
      }
    }
  }, [application, mountApplication, applicationName])

  return (
    <SectionContainer title={applicationName} defaultAction>
      <Spin
        spinning={
          application.status !== ApplicationStatus.LOADED &&
          application.status !== ApplicationStatus.ERROR
        }
      >
        {application &&
        (application.status === ApplicationStatus.ERROR || error !== '') ? (
          <div className="load-error">
            {applicationName} application not available.
          </div>
        ) : container ? (
          <div className="fmay-app--external-container" id={`${container}`} />
        ) : null}
      </Spin>
    </SectionContainer>
  )
}
export default ExternalApplication
