import React from 'react'
import SectionContainer from '../sections/main/SectionContainer'

const RouteUnknown = (props) => {
  return (
    <SectionContainer alwaysShow={true}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          height: '60vh',
        }}
      >
        <img src="/oops.png" alt="oops" style={{ width: 400 }} />
        <p>Oops, can't find what you are looking for...</p>
      </div>
    </SectionContainer>
  )
}

export default RouteUnknown
