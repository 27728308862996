import React, { useEffect } from 'react'
import SectionContainer from '../main/SectionContainer'
import Login from '../registered/Login'
import { useDispatch, useSelector } from 'react-redux'
import ContributorProfile from './ContributorProfile'
import { CURRENT_ACTION } from '../../model/navigation'
import './contributor.css'
import { getCurrentUser, getProfile } from '../../external/FirebaseAPI'
import { setUser } from '../../model/user'

const Contributor = () => {
  const user = useSelector((state: any) => state.user)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch({ type: CURRENT_ACTION, payload: undefined })
  }, [dispatch])

  useEffect(() => {
    if (!user) {
      // we may be logged in but handling a hard refresh
      const current = getCurrentUser()
      if (current) {
        // get profile and set the user in the store
        getProfile(current).then((profile) => {
          dispatch(
            setUser({
              displayName: current.displayName
                ? current.displayName
                : 'Not Set',
              email: current.email ? current.email : 'Not Set',
              uid: current.uid,
              photoURL: current.photoURL ?? '',
              providerId: current.providerData[0]
                ? current.providerData[0].providerId
                : '',
              profile: profile,
            })
          )
        })
      }
    }
  }, [user, dispatch])

  const register = user && !user.profile?.roles.includes('contributor')

  return (
    <SectionContainer>
      {user && !register ? (
        <ContributorProfile />
      ) : user ? (
        <div>Logged in as {user.email}</div>
      ) : (
        <Login role="contributor" register={register} />
      )}
    </SectionContainer>
  )
}

export default Contributor
