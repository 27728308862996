import firebase from 'firebase/app'

/**
 * Structure for a registered user
 *
 * {
 *    id: <firebase auth id>,
 *    seenAs: <string>,
 *    company: <string>,
 *    url: <string>,
 *    anonymous: <true|false>,
 *    roles: string[]
 * }
 */
export const getProfile = async (user) => {
  var userId = user.uid
  const result = await firebase
    .database()
    .ref(`/users/${userId}/profile`)
    .once('value')
  return await result.val()
}

export const setProfile = (id, profile) => {
  firebase.database().ref(`/users/${id}/profile`).set(profile)
}

export const setUserProfile = (id, profile) => {
  firebase.database().ref(`/users/${id}/profile`).set(profile)
}
export const getPledges = async (user) => {
  var userId = user.uid
  const result = await firebase
    .database()
    .ref('/pledges/' + userId)
    .once('value')
  return await result.val()
}

export const setPledges = (id, pledges) => {
  firebase
    .database()
    .ref('/pledges/' + id)
    .set(pledges)
}

export const signInEmail = async (email, password) => {
  return firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then((res) => {
      return res
    })
}

export const signoutUser = () => {
  firebase.auth().signOut()
}

export const updateCurrentUser = (user) => {
  firebase.auth().updateCurrentUser(user)
}

export const getRegisteredUsers = async (storedUser) => {
  const user = firebase.auth().currentUser
  if (user) {
    const read = await firebase.database().ref('users').once('value')
    const users = await read.val()
    return users
  }
  return []
}

export const createUserAccount = async ({ email, password }) => {
  return firebase
    .auth()
    .createUserWithEmailAndPassword(email, password)
    .then((result) =>
      firebase.database().ref(`/users/${result.user.uid}`).set({
        email: result.user.email,
        name: result.user.displayName,
      })
    )
}

export const resetUserPassword = async (email) => {
  firebase.auth().sendPasswordResetEmail(email)
}

export const getCurrentUser = () => {
  return firebase.auth().currentUser
}
