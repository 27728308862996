import { Octokit } from '@octokit/rest'
import { createAppAuth } from '@octokit/auth-app'
import { store } from '../App'
import { setGHExpiration, setGHToken } from '../model/github'
import { IUser } from '../model/types/auth/IUser'
import IProject from '../model/types/IProject'

export const USER_AGENT = 'FMAY-Software 1.0.0'

export const getToken = async () => {
  const github = store.getState().github

  if (!github.key || github.key === '') {
    return undefined
  }

  var token = github.token
  const exp = new Date(github.expiration)
  const now = new Date()
  if (!token || exp < now) {
    const auth = createAppAuth({
      appId: 87055,
      privateKey: github.key,
      installationId: 12729678,
      clientId: process.env.REACT_APP_GITHUB_CLIENT_ID,
      clientSecret: process.env.REACT_APP_GITHUB_CLIENT_SECRET,
    })

    const installationAuthentication: any = await auth({ type: 'installation' })
    store.dispatch(setGHToken(installationAuthentication.token))
    store.dispatch(setGHExpiration(installationAuthentication.expiresAt))
    token = installationAuthentication.token
  }
  return token
}

export const getOctokits = async (userToken: any) => {
  const appToken = await getToken()
  return {
    appKit: new Octokit({ auth: appToken, userAgent: USER_AGENT }),
    userKit: userToken ? new Octokit({ auth: userToken }) : undefined,
  }
}

export const sendProjectOwnershipRequest = async (
  organization: string,
  user: IUser,
  project: IProject
) => {
  const { appKit } = await getOctokits(undefined)
  return await appKit.teams.createDiscussionCommentInOrg({
    org: organization,
    team_slug: 'Owners',
    discussion_number: 2,
    body: `User: ${`${user.email} - ${user.displayName}`} is requesting ownership with details: \n ${JSON.stringify(
      {
        project: project.name,
        repository: project.repository_name,
        home: project.home,
      },
      null,
      2
    )}`,
  })
}
