import {
  Application,
  ApplicationStatus,
} from '../../model/types/ApplicationTypes'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useProjects, { STATES } from '../../sections/projects/hooks/useProjects'
import { IModelRoot } from '../../model/types/IModelRoot'
import { setExternalApplication } from '../../model/external-apps'

const configuration = {
  dev_host: 'http://localhost:3000/dev',
  prod_host: 'https://l3dibk3x0f.execute-api.us-east-1.amazonaws.com/dev',
}

export const useApplication = (
  host: string,
  path: string,
  name: string,
  type: string,
  branding: string,
  port?: number
): {
  mountApplication: (module: any, destination: string) => boolean
  unloadApplication: () => void
  application: Application
} => {
  const user = useSelector((state: any) => state.user)
  const credential = useSelector((state: any) => state.credential)
  const assignedProjects = useProjects(STATES.ASSIGNED)
  const dispatch = useDispatch()
  const potentialApp = useSelector((state: IModelRoot) =>
    state.external.find((a) => a.name === name)
  )
  const application = potentialApp
    ? potentialApp
    : {
        name: name,
        status: ApplicationStatus.UNLOADED,
        module: undefined,
      }

  const contributorProjects = assignedProjects.filter(
    (p: any) => p.owner.email === user.email
  )

  const cleansedName = name.replace(/\s/g, '')

  const load = useCallback(() => {
    const portParam = port ? `&port=${port}` : ''
    dispatch(
      setExternalApplication({
        ...application,
        status: ApplicationStatus.LOADING,
        module: undefined,
      })
    )
    const server =
      process.env.NODE_ENV === 'development'
        ? configuration.dev_host
        : configuration.prod_host
    fetch(
      `${server}/external/${cleansedName}/manifest?host=${host}${portParam}&path=${path}`
    )
      .then((res) => res.json())
      .then((manifest) => {
        const promises = Object.keys(manifest['files'])
          .filter((key) => key.endsWith('.js'))
          .reduce((sum: Promise<any>[], key) => {
            sum.push(
              new Promise((resolve: any) => {
                const path = `${server}/external/${cleansedName}?host=${host}${portParam}&modulePath=${manifest['files'][key]}`
                const script = document.createElement('script')
                script.onload = () => {
                  resolve()
                }
                script.src = path
                document.head.appendChild(script)
              })
            )
            return sum
          }, [])
        const allPromises = [
          ...promises,
          Object.keys(manifest['files'])
            .filter((key) => key.endsWith('.css'))
            .reduce((sum: Promise<any>[], key) => {
              sum.push(
                new Promise((resolve: any) => {
                  const path = `${server}/external/${cleansedName}/style?host=${host}${portParam}&path=${manifest['files'][key]}`
                  const link = document.createElement('link')
                  link.rel = 'stylesheet'
                  link.href = path
                  link.type = 'text/css'
                  document.head.insertBefore(
                    link,
                    document.getElementsByTagName('head')[0].firstChild
                  )
                  resolve(true)
                })
              )
              return sum
            }, []),
        ]
        Promise.allSettled(allPromises).then(() => {
          dispatch(
            setExternalApplication({
              ...application,
              status: ApplicationStatus.LOADED,
              module: '',
            })
          )
        })
      })
      .catch((e: any) => {
        dispatch(
          setExternalApplication({
            ...application,
            status: ApplicationStatus.ERROR,
            module: '',
          })
        )
      })
  }, [cleansedName, host, path, port, application, dispatch])

  const unloadApplication = () => {}

  const mountApplication = (module: any, destination: string): boolean => {
    if (type === 'react') {
      const theWindow = window as any
      const render = `render${cleansedName}`
      if (theWindow[`${render}`]) {
        theWindow[`${render}`](
          destination,
          user,
          contributorProjects,
          credential
        )
        return true
      }
    }
    return false
  }

  /* ts-ignore: react-hooks/exhaustive-deps */
  useEffect(() => {
    if (application.status === ApplicationStatus.UNLOADED) {
      load()
    }
  }, [application, load])

  return {
    mountApplication: mountApplication,
    unloadApplication: unloadApplication,
    application: application,
  }
}
