import { FMAYUpdateListener } from '../model/types/FMAYUpdateListener'

let socket: WebSocket = new WebSocket(
  'wss://3mtpc5eptj.execute-api.us-east-1.amazonaws.com/prod'
)

const listeners: FMAYUpdateListener[] = []

socket?.addEventListener('message', (event: any) => {
  // TODO: why in the world do we need a double JSON parse here?
  const data = JSON.parse(JSON.parse(event.data))
  listeners.forEach((l) => l.onMessage(data))
})

export const useFmayUpdates = () => {
  const registerListener = (listener: FMAYUpdateListener) => {
    if (!listeners.map((l) => l.id).includes(listener.id)) {
      listeners.push(listener)
    }
  }

  const unregisterListener = (listener: FMAYUpdateListener) => {
    listeners.filter((l) => l.id === listener.id)
  }

  return {
    registerListener,
    unregisterListener,
  }
}
