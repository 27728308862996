import { combineReducers } from 'redux'
import { navigationAction } from './navigation'
import { projects, repositories } from './projects'
import { user } from './user'
import { github } from './github'
import { external } from './external-apps'
import { credential } from './credential'

export const rootReducer = combineReducers({
  action: navigationAction,
  projects: projects,
  repositories: repositories,
  user: user,
  github: github,
  external: external,
  credential: credential,
})
