import React from 'react'
import './App.css'
import Root from './routing/Root'
import { createStore } from 'redux'
import { Provider } from 'react-redux'
import { rootReducer } from './model/root'
import { persistStore, persistReducer } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import storage from 'redux-persist/lib/storage'
import ReactGA from 'react-ga'
import firebase from 'firebase/app'

const persistConfig = {
  key: 'fmay',
  storage: storage,
  blacklist: ['external'], // do not persist external application load state
}

export const store = createStore(persistReducer(persistConfig, rootReducer))
const persistor = persistStore(store)

window.store = store

var firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: 'fmay-software-site.firebaseapp.com',
  databaseURL: 'https://fmay-software-site.firebaseio.com',
  projectId: 'fmay-software-site',
  storageBucket: 'fmay-software-site.appspot.com',
  messagingSenderId: '809799122625',
  appId: '1:809799122625:web:2ff47a7a0b93afa3fd1e40',
  measurementId: 'G-87VJ6GKV7Y',
}
// Initialize Firebase
firebase.initializeApp(firebaseConfig)
firebase.analytics()

ReactGA.initialize('UA-000000-01', {
  debug: true,
  titleCase: false,
  gaOptions: {
    userId: 123,
    siteSpeedSampleRate: 100,
  },
})

const App = () => {
  window.store = store

  return (
    <div className="App">
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Root />
        </PersistGate>
      </Provider>
    </div>
  )
}

export default App
