import { Button, Checkbox, Form, Input, Spin } from 'antd'
import React from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ADD_PROJECT } from '../../../model/projects'
import { createProjectOnGithub } from '../../../model/services/projects/project'
import { IModelRoot } from '../../../model/types/IModelRoot'
import IProject from '../../../model/types/IProject'
import useProjects, { STATES } from '../hooks/useProjects'

export interface ProjectFormProps {
  setProjectCreated: (project: IProject) => void
}
const ProjectForm = (props: ProjectFormProps) => {
  const { setProjectCreated } = props
  const projects = useProjects(STATES.ANY)
  const dispatch = useDispatch()
  const user = useSelector((state: IModelRoot) => state.user)
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()

  const handleProjectCreation = (values: IProject) => {
    setLoading(true)
    // github replaces spaces with -
    values.name = values.name.replaceAll(' ', '-')
    values = {
      ...values,
      email: user.profile?.url || user.email,
      repository_name: values.name,
      home: `https://github.com/FMAY-Software/${values.name}`,
      deliverables: [{ target: 'None', url: 'None' }],
      demonstration: { url: '' },
      owner: {
        first_name: 'FMAY-Incubator',
        last_name: '',
        email: 'travis.london@fmay.net',
        phone: '5203730727',
      },
      clients: [user.email],
    }
    createProjectOnGithub(user, values, values.private)
      .then((result) => {
        setLoading(false)
        form.resetFields()
      })
      .then((_) => setProjectCreated(values))
    dispatch({ type: ADD_PROJECT, payload: values })
  }

  const headLayout = {
    wrapperCol: { offset: 0, span: 16 },
  }

  return (
    <Form
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 16 }}
      layout="horizontal"
      size="small"
      style={{ padding: 20, width: '75%' }}
      onFinish={(values) => handleProjectCreation(values)}
      onFinishFailed={() => console.log('Failed')}
      form={form}
    >
      <Spin spinning={loading} tip="Creating project...">
        <Form.Item
          rules={[
            {
              required: true,
              message: 'You want to name your project, no?',
            },
            () => ({
              validator(rule, value) {
                const reject = projects.includes(
                  (p: IProject) => p.name === value
                )
                if (reject) {
                  return Promise.reject(
                    'Too late, a project with the same name has already been registered.'
                  )
                }
                return Promise.resolve()
              },
            }),
          ]}
          style={{ marginBottom: 5 }}
          label="Name"
          name="name"
        >
          <Input />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: 'Just one sentence, please.',
            },
          ]}
          style={{ marginBottom: 5 }}
          label="About"
          name="about"
        >
          <Input />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: 'A description would be helpful for the pledgors.',
            },
          ]}
          style={{ marginBottom: 5 }}
          label="Description"
          name="description"
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          {...headLayout}
          style={{ textAlign: 'left' }}
          valuePropName="checked"
          label="Private"
          name="private"
        >
          <Checkbox />
        </Form.Item>
        <Button size="small" htmlType="submit">
          Create
        </Button>
      </Spin>
    </Form>
  )
}

export default ProjectForm
