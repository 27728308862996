import { useDispatch, useSelector } from 'react-redux'
import { getProjects, getRepositories } from '../../../external/GitProjects'
import { getGHKey } from '../../../external/S3API'
import { GITHUB_KEY } from '../../../model/github'

import {
  PROJECTS_LOADING,
  ADD_PROJECT,
  SET_REPOSITORIES,
  REPOSITORIES_LOADING,
  LAST_PROJECT_LOAD,
  CLEAR_PROJECTS,
} from '../../../model/projects'

export const STATES = Object.freeze({
  ASSIGNED: '_assigned',
  PENDING: '_pending',
  INCUBATION: '_incubation',
  CLIENT: '_client',
  ANY: '_any',
})

const useProjects = (projectState) => {
  const dispatch = useDispatch()

  const github = useSelector((state) => state.github)
  const repositories = useSelector((state) => state.repositories.data || [])
  const repositoriesLoading = useSelector(
    (state) => state.repositories.loading || false
  )
  var projects = useSelector((state) => state.projects.data || [])
  const projectsLoading = useSelector(
    (state) => state.projects.loading || false
  )
  const lastLoad = useSelector((state) => state.projects.lastLoad)
  const client = useSelector((state) => state.user?.email)
  if (
    github === undefined ||
    github.key === undefined ||
    typeof github.key === Object ||
    github.key === ''
  ) {
    getGHKey().then((key) => {
      dispatch({ type: GITHUB_KEY, payload: key })
    })
    return []
  }

  const expiry = 1000 * 60 * 10
  const refreshProjects =
    lastLoad === undefined || lastLoad + expiry <= Date.now()
  if (repositories.length > 0 && refreshProjects) {
    dispatch({ type: SET_REPOSITORIES, payload: [] })
    dispatch({ type: CLEAR_PROJECTS })
    dispatch({ type: REPOSITORIES_LOADING, payload: false })
    dispatch({ type: PROJECTS_LOADING, payload: false })
    return []
  }

  if (repositories.length === 0 && !repositoriesLoading) {
    dispatch({ type: REPOSITORIES_LOADING, payload: true })
    getRepositories('FMAY-Software', github.userToken, client).then((repos) => {
      dispatch({ type: SET_REPOSITORIES, payload: repos })
      dispatch({ type: REPOSITORIES_LOADING, payload: false })
      dispatch({ type: LAST_PROJECT_LOAD, payload: Date.now() })
    })
  }

  if (projects.length === 0 && repositories.length > 0 && !projectsLoading) {
    dispatch({ type: PROJECTS_LOADING, payload: true })
    repositories.forEach((r) => {
      getProjects('FMAY-Software', r.name).then((result) => {
        if (result !== '') {
          result = {
            ...result,
            home: r.html_url,
            repository_name: r.name,
            private: r.private,
          }
          dispatch({ type: ADD_PROJECT, payload: result })
          dispatch({ type: PROJECTS_LOADING, payload: false })
        }
      })
    })
  }

  projects = projects.filter((p) => p.name)

  projects = projects.map((p) => {
    if (
      p.owner !== undefined &&
      p.owner.first_name !== 'FMAY-Incubator' &&
      p.pledge_target &&
      p.pledge_target !== ''
    ) {
      const addState = { ...p, state: 'Active' }
      return addState
    } else if (p.owner === undefined || p.pledge_target === undefined) {
      const addState = { ...p, state: 'Inactive' }
      return addState
    } else if (
      p.owner &&
      p.owner.first_name === 'FMAY-Incubator' &&
      p.pledge_target !== undefined
    ) {
      const addState = { ...p, state: 'Pending' }
      return addState
    } else {
      return p
    }
  })

  switch (projectState) {
    case STATES.ASSIGNED:
      return projects.filter((p) => p.state === 'Active')
    case STATES.INCUBATION:
      return projects.filter((p) => p.state === 'Inactive')
    case STATES.PENDING:
      return projects.filter((p) => p.state === 'Pending')
    case STATES.CLIENT:
      return projects.filter(
        (p) => client && p.clients && p.clients.includes(client)
      )
    default:
      return projects
  }
}

export default useProjects
