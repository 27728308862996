import React from 'react'
import ConversationCard from '../../widgets/ConversationCard'

const Comment = (props) => {
  const { author, date, body } = props
  const created = new Date(date).toLocaleString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })
  const time = new Date(date).toLocaleString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  })
  return (
    <ConversationCard
      title={author}
      timeSpecification={` on ${created} at ${time}`}
      content={body}
      width={'100%'}
    />
  )
}

export default Comment
