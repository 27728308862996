import React from 'react'
import { Carousel } from 'antd'
import DescriptiveSection from '../../widgets/DescriptiveSection'
import LandingPage from './LandingPage'

const carouselContent = [
  {
    content: (
      <>
        <p>
          Everything that we design here is delivered as open source software.
        </p>
        <p>Through our own creation and yours, software is delivered for us.</p>
      </>
    ),
    title: 'Always for us',
  },
  {
    content: (
      <>
        <p>Projects drive our ideas from napkin to deployment.</p>
        <br />
      </>
    ),
    title: 'Projects',
  },
  {
    content: (
      <>
        <p>Our thoughts realized.</p>
        <p>
          The goal of FMAY is to produce complete products, provide
          enhancements, or anything between.
        </p>
      </>
    ),
    title: 'Creation',
  },
  {
    content: (
      <>
        <p>
          Let’s face it software will always have issues regardless of how much
          testing was done.
        </p>
        <p>
          Projects at FMAY are tested before delivery, but in such a case where
          a project needs help we are here to support.
        </p>
      </>
    ),
    title: 'Support',
  },
  {
    content: (
      <>
        <p>
          FMAY is happy to help you implement your ideas within your own
          organization.
        </p>
        <p>
          Whether you want help building something close sourced or you would
          like to champion an open source project, FMAY can help.
        </p>
      </>
    ),
    title: 'Contracting',
  },
]

const FmayIntro = (props) => {
  return (
    <>
      <img
        src="/connected.png"
        alt="Connected"
        style={{
          position: 'absolute',
          objectFit: 'cover',
          width: '100%',
          height: '89vh',
        }}
      />
      <LandingPage anchor="root" next_anchor="about" next_title="About Us">
          <Carousel
            arrows={false}
            draggable={true}
            autoplay={true}
            autoplaySpeed={5000}
            pauseOnHover={true}
            dotPosition="bottom"
            effect="fade"
            style={{
              backgroundColor: 'rgba(19,92,156,0.7',
              height: 400,
              width: 'calc(100vw - 15px)'
            }}
          >
            {carouselContent.map(({ content, title }, i) => (
              <DescriptiveSection
                content={content}
                title={title}
                key={`${title}-${i}`}
              />
            ))}
          </Carousel>
      </LandingPage>
    </>
  )
}

export default FmayIntro
