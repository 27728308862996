import { Button } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { IModelRoot } from '../../model/types/IModelRoot'
import SignInButton from '../../widgets/SignInButton'
import SectionContainer from '../main/SectionContainer'
import AsContributor from './AsContributor'
import AssignedProjects from './AssignedProjects'
import Pledges from './Pledges'
import './Contributions.css'

const ContributorHome = (): JSX.Element => {
  const displayName = useSelector(
    (state: IModelRoot) => state.user?.displayName
  )
  const history = useHistory()

  const welcomeTitle = displayName ? 'Welcome, ' + displayName : 'Welcome'

  const openSeventyEight = () => {
    history.push('/books')
  }

  return (
    <SectionContainer title={welcomeTitle}>
      <div className="fmay-contributrions--home">
        {displayName === undefined ? (
          <div className="fmay-contributrions--grid">
            <div className="fmay-contributions--tables">
              <p>Please sign in if you have previously contributed.</p>
              <SignInButton history={history} size="small" />
            </div>
          </div>
        ) : (
          <div className="fmay-contributions--grid">
            <div className="fmay-contributions--projects">
              <AssignedProjects />
            </div>
            <div className="fmay-contributions--pledges">
              <Pledges />
            </div>
            <div className="fmay-contributions--tools">
              <p>Contribution Tools</p>
              <AsContributor
                alternate={
                  <>
                    <p>Tools are available to project contributors</p>
                  </>
                }
              >
                <Button onClick={() => openSeventyEight()}>Work Logs</Button>
              </AsContributor>
            </div>
          </div>
        )}
      </div>
    </SectionContainer>
  )
}

export default ContributorHome
