import React from 'react'
import { Table, Tooltip } from 'antd'

const ProjectsListView = props => {
  const { filteredProjects, handleProjectSelected } = props
  // Table sorter will not work with undefined values
  const filterProjects = filteredProjects.map(p => {
    if (p.status === undefined) {
      p.status = ''
      return p
    } else {
      return p
    }
  })
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      sorter: (a, b) => a.name.localeCompare(b.name)
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: '30%',
      ellipsis: {
        showTitle: false
      },
      render: description => (
        <Tooltip placement='topLeft' title={description} color='blue'>
          {description}
        </Tooltip>
      )
    },
    {
      title: 'URL',
      dataIndex: 'home',
      key: 'home',
      width: '20%',
      render: home => {
        return (
          <a
            href={home}
            onClick={e => e.stopPropagation()}
            target='_blank'
            rel='noopener noreferrer'
            className='ant-dropdown-link'
          >
            {home}
          </a>
        )
      }
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      width: '10%',
      sorter: (a, b) => a.state.localeCompare(b.state)
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '10%',
      sorter: (a, b) => a.status.localeCompare(b.status)
    }
  ]
  return (
    <Table
      dataSource={filterProjects}
      columns={columns}
      bordered
      pagination={{ pageSize: 7, hideOnSinglePage: true }}
      size='small'
      style={{ cursor: 'pointer' }}
      rowKey={'name'}
      onRow={record => {
        return {
          onClick: () => handleProjectSelected(record, 'tableClick')
        }
      }}
    />
  )
}

export default ProjectsListView
