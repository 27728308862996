import React, { useState } from 'react'
import { Button } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect } from 'react-redux'
import { useHistory } from 'react-router'
import DefaultAction from './DefaultAction'

const Actions = (props) => {
  const [isOver, setIsOver] = useState(false)
  const { action, defaultAction } = props
  const history = useHistory()

  var currentAction = action
  if (defaultAction !== undefined) {
    currentAction = DefaultAction
  }
  if (!currentAction.icon && !currentAction.label) {
    return null
  }

  return (
    <Button
      onMouseEnter={() => setIsOver(true)}
      onMouseLeave={() => setIsOver(false)}
      style={{ border: 0, backgroundColor: 'transparent' }}
      size="sm"
      onClick={() => {
        currentAction.action(history)
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: 12,
          color: isOver ? 'seagreen' : 'dodgerblue',
        }}
      >
        <FontAwesomeIcon icon={currentAction.icon} />
        {currentAction.label}
      </div>
    </Button>
  )
}

const mapStateToProps = (state) => {
  return {
    action: state.action,
  }
}

export default connect(mapStateToProps)(Actions)
